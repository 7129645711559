import React from 'react'
import PropTypes from 'prop-types'
import { GetSiteMetaData } from '../../metadata'

const propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
}

// const helpCss = css`
//   text-align: center;
//   background-color: #eef0fb;
//   padding: 1rem 0;
// `

const Support403 = ({ data }) => {
  const { content } = data

  const siteMetaData = GetSiteMetaData()

  let zdKey = 'b4743d0b-5fe6-4eed-be3b-62cd904ec484'
  if (siteMetaData.title === 'Internic.ca') {
    zdKey = '315dd874-9623-425e-b5f8-3e37b0ff32f2'
  }

  const nativeCode = `fetch("https://api.ipify.org?format=json").then(r=>r.json()).then(r=>document.querySelector("#ip-address").innerHTML=\"${content}\"+r.ip )`

  return (
    <>
      <script
        id="ze-snippet"
        src={`https://static.zdassets.com/ekr/snippet.js?key=${zdKey}`}
      >
        {' '}
      </script>
      <script dangerouslySetInnerHTML={{ __html: nativeCode }} />
    </>
  )
}

Support403.displayName = 'Support403'
Support403.propTypes = propTypes

export { Support403 }
