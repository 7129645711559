import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box, Text, Section } from '@rebeldotcom/components'
import { Title } from '../../title'
import { Content } from '../../content'
import { containerVariants } from '../../../utilities/panels'

const propTypes = {
  data: PropTypes.shape({
    bg: PropTypes.string,
    color: PropTypes.string,
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        title: PropTypes.shape({
          main: PropTypes.shape({
            text: PropTypes.string,
          }),
        }),
      })
    ),
    theme: PropTypes.oneOf(['light', 'dark']),
    title: PropTypes.shape({
      bottom: PropTypes.shape({
        bgColor: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      main: PropTypes.shape({
        bgColor: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      top: PropTypes.shape({
        bgColor: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }),
  }).isRequired,
}

const FAQ = ({ data }) => {
  const { title, items, id, bg, theme, color } = data
  return (
    <Section
      bg={bg || containerVariants[theme || 'light'].bg}
      color={containerVariants[theme || 'light'].text}
      containerProps={{
        maxWidth: 'containers.md',
      }}
      id={id}
      py={[4, 5]}
    >
      <Title title={title} variant={['teraBold', 'petaBold']} />

      <Box flexWrap="wrap" justifyContent="center" mt={5}>
        {items.map((item, idx) => {
          return (
            <Box
              key={`${item.title.main.text}-${idx}`}
              flexDirection="column"
              maxWidth="55rem"
              mb={4}
              px={3}
              width={[1, 0.48]}
            >
              <Text
                color={color}
                fontFamily="title"
                lineHeight="title"
                variant="gigaBold"
              >
                {item.title.main.text}
              </Text>
              <Content color={color} lineHeight="body" mt={4} variant="pound">
                {item.content}
              </Content>
            </Box>
          )
        })}
      </Box>
    </Section>
  )
}

FAQ.propTypes = propTypes

export { FAQ }

export const faqFragment = graphql`
  fragment FAQPanel on Panel {
    title {
      ...TitleProps
    }
    theme
    color
    items {
      title {
        main {
          text
        }
      }
      content
    }
  }
`
