import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { Box, Text, Section } from '@rebeldotcom/components'
import { Title } from '../../title'
import { containerVariants } from '../../../utilities/panels'

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    theme: PropTypes.oneOf(['light', 'dark']),
    title: PropTypes.shape({
      bottom: PropTypes.string,
      main: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      top: PropTypes.shape({
        bgColor: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }),
  }).isRequired,
}

const CareersList = ({ theme }) => {
  return (
    <Box
      alignItems="center"
      flexDirection={['column', 'column', 'row']}
      maxWidth="110rem"
      mt={4}
      pb={4}
      width={[1, 1, 0.9]}
    >
      <StaticQuery
        query={graphql`
          query {
            allCareer {
              nodes {
                link
                location
                title
              }
            }
          }
        `}
        render={({ allCareer: { nodes } }) => {
          if (!nodes.length) {
            return (
              <Box mt={4} p={4}>
                <Text>
                  No open positions at the moment, check back again soon!
                </Text>
              </Box>
            )
          }

          return nodes.map(career => (
            <Box
              key={career.title}
              flexDirection="column"
              ml={4}
              width={3 / 4}
              height={'15rem'}
            >
              <Text fontSize={3} fontWeight="semi">
                <a href={career.link}>{career.title}</a>
              </Text>
              <Text fontSize={3} fontWeight="semi">
                Location: {career.location}
              </Text>
            </Box>
          ))
        }}
      />
    </Box>
  )
}

const Careers = ({ data }) => {
  const { title, theme, id } = data

  return (
    <Section
      bg={containerVariants[theme].bg}
      color={containerVariants[theme].text}
      id={id}
      px={[2, 3, 5]}
      py={5}
    >
      <Title theme={theme} title={title} />
      <CareersList theme={theme} />
    </Section>
  )
}

Careers.propTypes = propTypes

export { Careers }

export const ourCareers = graphql`
  fragment Careers on Panel {
    title {
      ...TitleProps
    }
  }
`
