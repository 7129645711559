/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink, graphql } from 'gatsby'
import {
  Stack,
  Box,
  Text,
  Link,
  Icon,
  Grid,
  Button,
  redesignTheme,
} from '@rebeldotcom/components'
import { css } from 'styled-components'
import SectionBG from '../../section-bg'
import { Title } from '../../title'
import { Content } from '../../content'
import { containerVariants, handleCTAClick } from '../../../utilities/panels'

const { colors, media } = redesignTheme

const propTypes = {
  data: PropTypes.shape({
    bg: PropTypes.string,
    ctaPair: PropTypes.arrayOf(
      PropTypes.shape({
        cta: PropTypes.shape({
          color: PropTypes.string,
          colorMobile: PropTypes.string.isRequired,
          href: PropTypes.string,
          text: PropTypes.string,
          title: PropTypes.string,
          variant: PropTypes.string,
        }),
        description: PropTypes.string,
        features: PropTypes.array,
        fineprint: PropTypes.string,
        size: PropTypes.oneOf(['full', 'large', 'medium', 'small']),
        title: PropTypes.shape({
          bottom: PropTypes.string,
          main: PropTypes.shape({
            text: PropTypes.string,
            textColor: PropTypes.string,
          }),
          top: PropTypes.shape({
            bgColor: PropTypes.string,
            text: PropTypes.string,
            textColor: PropTypes.string,
          }),
        }),
      })
    ),
    id: PropTypes.string.isRequired,
    image: PropTypes.shape({
      altText: PropTypes.string,
      background: PropTypes.bool,
      dim: PropTypes.number,
      path: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.shape({}),
        }),
      }),
    }),
    theme: PropTypes.oneOf(['light', 'dark']),
  }).isRequired,
}

const CTAPair = ({ data }) => {
  const { bg, ctaPair, image, theme, id } = data

  const renderCTA = cta => {
    if (!cta) return null

    const responsiveButton = css`
      background-color: ${colors[cta.color]};
      color: ${colors.white};
      border-color: ${colors[cta.color]};
      ${media.smallAndDown`
	    background-color: ${colors[cta.colorMobile]};
	    color: ${colors.white};
	    border-color: ${colors[cta.colorMobile]}
	  `}
    `

    if (cta.type === 'addToCart') {
      return (
        <Box
          alignItems="center"
          flexWrap="wrap"
          justifyContent="center"
          mt={[3, 4, 5]}
        >
          <Button
            key={cta.id}
            ariaLabel={cta.title || cta.text}
            css={responsiveButton}
            id={cta.id}
            onClick={() => handleCTAClick(cta)}
            rel="nofollow"
            variant={cta.variant}
          >
            {cta.text}
          </Button>
        </Box>
      )
    }
    return (
      <Box
        alignItems="center"
        flexWrap="wrap"
        justifyContent="center"
        mt={[3, 4, 5]}
      >
        <Link
          ariaLabel={cta.title}
          as={cta.isCMS ? GatsbyLink : Link}
          css={responsiveButton}
          href={cta.href}
          id={cta.id}
          size={cta.size}
          to={cta.href}
          variant={cta.variant}
        >
          {cta.text}
        </Link>
      </Box>
    )
  }

  return (
    <SectionBG
      bg={bg}
      id={id}
      image={image}
      position="relative"
      px={[2, 4]}
      py={[4, 5]}
      theme={theme}
    >
      <Grid
        alignItems="space-around"
        gridGap={[5, 7]}
        gridTemplateColumns={['1fr', '1fr 1fr']}
        justifyContent="space-around"
        maxWidth="containers.xl"
        width="100%"
      >
        {ctaPair.map(
          ({ description, features, fineprint, cta, title }, idx) => {
            return (
              <Stack
                key={idx}
                justifyContent="space-between"
                maxWidth="containers.md"
              >
                <Title
                  alignItems="center"
                  alignment={['center']}
                  color={containerVariants[theme || 'light'].text}
                  theme={theme}
                  title={title}
                  variant={['tera', 'peta', 'exa']}
                />
                {features && (
                  <Box
                    flexDirection={['column', 'row']}
                    flexWrap="wrap"
                    justifyContent={['center', 'space-between']}
                    mt={[3, 4]}
                  >
                    {features.map(feature => {
                      return (
                        <Text
                          key={feature}
                          alignItems="center"
                          display="flex"
                          fontSize={[0, 1]}
                          lineHeight="body"
                          py={2}
                          width={[1, '49%']}
                        >
                          <Icon mr={2} name="checkmark-outline" />
                          {feature}
                        </Text>
                      )
                    })}
                  </Box>
                )}
                {description && (
                  <Box justifyContent="center">
                    <Text mt={[3, 4]} textAlign="center">
                      {description}
                    </Text>
                  </Box>
                )}
                {renderCTA(cta)}
                {fineprint && (
                  <Box justifyContent="center">
                    <Content
                      color={containerVariants[theme || 'light'].text}
                      fontSize={1}
                      lineHeight="title"
                      maxWidth="50rem"
                      mt={4}
                      textAlign="center"
                    >
                      {fineprint}
                    </Content>
                  </Box>
                )}
              </Stack>
            )
          }
        )}
      </Grid>
    </SectionBG>
  )
}

CTAPair.propTypes = propTypes

CTAPair.displayName = 'CTAPair'

export { CTAPair }

export const ctaPairFragment = graphql`
  fragment CTAPairPanel on Panel {
    ctaPair {
      title {
        ...TitleProps
      }
      fineprint
      cta {
        id
        text
        href
        title
        variant
        color
        colorMobile
      }
    }
  }
`
