import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  Box,
  Text,
  Section,
  Icon,
  Heading,
  Skeleton,
} from '@rebeldotcom/components'
import { containerVariants, sliderScroller } from '../../../utilities/panels'

const propTypes = {
  data: PropTypes.shape({
    bg: PropTypes.string,
    color: PropTypes.string,
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string.isRequired,
        reviewer: PropTypes.string.isRequired,
      })
    ),
    theme: PropTypes.oneOf(['light', 'dark']),
  }).isRequired,
}

const Testimonial = ({ data }) => {
  const { items, id, bg, theme, color } = data
  const [isCurrentlyScrolling, setIsCurrentlyScrolling] = useState(false)

  function handleSliderNavigation(scrollDirection = '+') {
    if (isCurrentlyScrolling) return // Stops spam
    setIsCurrentlyScrolling(true)
    try {
      const sliderNode = document.getElementById('slider')
      sliderScroller(sliderNode, scrollDirection)
      setTimeout(() => setIsCurrentlyScrolling(false), 700)
    } catch (err) {
      console.log(err.message || 'Slider is not working correctly.')
      setIsCurrentlyScrolling(false)
    }
  }

  return (
    <Section
      bg={bg || containerVariants[theme || 'light'].bg}
      color={containerVariants[theme || 'light'].text}
      containerProps={{
        maxWidth: 'containers.lg',
      }}
      id={id}
      py={[4, 5]}
    >
      <Box
        alignItems="center"
        justifyContent="center"
        position="relative"
        maxWidth="100%"
      >
        {items ? (
          <>
            <Box
              alignItems="center"
              justifyContent="flex-start"
              overflow="scroll"
              width="100%"
              id="slider"
              tabIndex="0"
              css={`
                scrollbar-width: none;
                -ms-overflow-style: none;
                &::-webkit-scrollbar {
                  display: none;
                }
              `}
            >
              {items.map((item, i) => {
                const { content, reviewer } = item
                return (
                  <Box
                    className="slider-item"
                    alignItems="center"
                    flexDirection="column"
                    justifyContent="center"
                    minWidth="100%"
                    maxWidth="100rem"
                    px={[4, 4]}
                    key={i}
                  >
                    <Heading
                      color={color}
                      lineHeight="body"
                      mb={2}
                      textAlign="center"
                      variant={['gigaBold', 'teraBold']}
                    >
                      {content}
                    </Heading>
                    <Box
                      alignItems="center"
                      flexDirection={['column', 'row', 'row']}
                    >
                      <Text color={color} mr={2} variant={['kilo', 'mega']}>
                        {`- ${reviewer}`}
                      </Text>
                    
                    </Box>
                  </Box>
                )
              })}
            </Box>
            <Icon
              height="2rem"
              width="2rem"
              name="arrow-right"
              onClick={() => handleSliderNavigation('-')}
              scale="(-1, -1)"
              title="previous-testimonial"
              titleId="chevron-left"
              position="absolute"
              css={`
                left: 0;
                cursor: pointer;
                transform: rotate(180deg);
                transition: 0.2s;
                &:hover {
                  transform: rotate(180deg) translate(2px);
                }
              `}
            />
            <Icon
              height="2rem"
              width="2rem"
              name="arrow-right"
              onClick={() => handleSliderNavigation('+')}
              title="next-testimonial"
              titleId="chevron-right"
              position="absolute"
              css={`
                right: 0;
                cursor: pointer;
                transition: 0.2s;
                &:hover {
                  transform: translate(2px);
                }
              `}
            />
          </>
        ) : (
          <Skeleton minHeight={5} />
        )}
      </Box>
    </Section>
  )
}

Testimonial.propTypes = propTypes

export { Testimonial }

export const testimonialFragment = graphql`
  fragment TestimonialPanel on Panel {
    theme
    color
    items {
      content
      reviewer
    }
  }
`
