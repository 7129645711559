import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link as GatsbyLink } from 'gatsby'
import {
  Icon,
  Link,
  Text,
  Section,
  Grid,
  Stack,
  Button,
} from '@rebeldotcom/components'
import { Title } from '../../title'
import { Content } from '../../content'
import Img from '../../image'
import {
  containerVariants,
  getImageProps,
  handleCTAClick,
} from '../../../utilities/panels'

const propTypes = {
  data: PropTypes.shape({
    bg: PropTypes.string,
    color: PropTypes.string,
    content: PropTypes.string,
    cta: PropTypes.shape({
      color: PropTypes.string,
      href: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['link', 'addToCart']),
      variant: PropTypes.string,
    }),
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        ctas: PropTypes.arrayOf(
          PropTypes.shape({
            color: PropTypes.string,
            href: PropTypes.string,
            id: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['link', 'addToCart']),
            variant: PropTypes.string,
          })
        ),
        icon: PropTypes.string,
        price: PropTypes.object,
        title: PropTypes.shape({
          bottom: PropTypes.shape({
            text: PropTypes.string,
            textColor: PropTypes.string,
          }),
          main: PropTypes.shape({
            text: PropTypes.string,
            textColor: PropTypes.string,
          }),
          top: PropTypes.shape({
            bgColor: PropTypes.string,
            text: PropTypes.string,
            textColor: PropTypes.string,
          }),
        }),
      })
    ).isRequired,
    maxColumns: PropTypes.number,
    maxWidth: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
    textOnly: PropTypes.bool,
    theme: PropTypes.oneOf(['light', 'dark']),
    title: PropTypes.shape({
      bottom: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      main: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      top: PropTypes.shape({
        bgColor: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }),
  }).isRequired,
  variant: PropTypes.oneOf(['old', 'new']),
}

const defaultProps = {
  variant: 'old',
}

const Spread = ({ data }) => {
  const {
    title,
    content,
    items,
    theme,
    maxColumns,
    maxWidth,
    bg,
    id,
    color,
    textOnly,
    cta,
  } = data

  const count = (items && items.length) || 0

  const rowCols = Math.min(4, count)

  const maxSpreadWidth = maxWidth ? `containers.${maxWidth}` : 'containers.lg'

  const mobileGrid = '1fr'
  const smallGrid =
    count % 2 === 0 ? 'repeat(2, 1fr)' : `repeat(${rowCols}, 1fr)`
  const mediumGrid =
    count % 3 === 0 ? 'repeat(3, 1fr)' : `repeat(${rowCols}, 1fr)`
  const largeGrid = `repeat(${Math.min(maxColumns, rowCols)}, 1fr)`

  const getIconColor = ({ iconColor }) => {
    return iconColor ? { color: iconColor } : { color: 'black' }
  }

  return (
    <Section
      bg={bg || containerVariants[theme || 'light'].bg}
      color={containerVariants[theme || 'light'].text}
      containerProps={{
        maxWidth: maxSpreadWidth,
      }}
      id={id}
      px={[4]}
      py={[3, 3, 5]}
    >
      {title || content ? (
        <Stack mb={4}>
          {title && (
            <Title
              lineHeight={['1.3']}
              mb={1}
              theme={theme}
              title={title}
              variant={['teraBold', 'petaBold']}
            />
          )}

          {content && (
            <Content
              color={color}
              maxWidth="containers.md"
              mt={3}
              textAlign="center"
              variant="mega"
            >
              {content}
            </Content>
          )}
        </Stack>
      ) : null}

      {items && items.length > 0 ? (
        <Grid
          gridGap={!textOnly ? [3, 5] : null}
          gridTemplateColumns={[mobileGrid, smallGrid, mediumGrid, largeGrid]}
          justifyContent="center"
          maxWidth={['containers.sm', 'containers.md', 'containers.lg']}
          mt={!textOnly ? 3 : null}
        >
          {items.map((item, idx) => {
            const itemAllignment =
              item.icon || item.image ? 'center' : 'flex-start'
            return (
              <Stack key={idx} alignItems={itemAllignment} p={1}>
                {item.icon && (
                  <Icon
                    {...getIconColor(item)}
                    height="8rem"
                    mt={[3, 4]}
                    name={item.icon}
                    title={item.title.main.text}
                    width="8rem"
                  />
                )}
                {item.image ? (
                  <Img
                    alt={item.image.altText}
                    width="100%"
                    {...getImageProps(item.image)}
                  />
                ) : null}

                {item.title?.main ? (
                  <Content
                    color={color}
                    fontFamily="title"
                    lineHeight="1.3"
                    mt={[3, 4]}
                    textAlign={itemAllignment}
                    variant="gigaBold"
                  >
                    {item.title.main.text}
                  </Content>
                ) : null}

                {item.content ? (
                  <Content
                    color={color}
                    lineHeight="body"
                    mt={3}
                    textAlign={itemAllignment}
                    variant="pound"
                  >
                    {item.content}
                  </Content>
                ) : null}

                {item.ctas && item.ctas.length > 0 ? (
                  <Stack
                    alignItems="flex-start"
                    flex="1"
                    flexWrap="wrap"
                    justifyContent="flex-end"
                    maxWidth="50rem"
                    mt={!textOnly ? 2 : null}
                  >
                    {item.ctas.map(cta => {
                      if (cta.type === 'addToCart') {
                        return (
                          <Button
                            key={cta.id}
                            ariaLabel={cta.title || cta.text}
                            color={cta.color}
                            id={cta.id}
                            onClick={() => handleCTAClick(cta)}
                            rel="nofollow"
                            variant={cta.variant}
                          >
                            {cta.text}
                          </Button>
                        )
                      }

                      const linkLocation = cta.isCMS
                        ? { to: cta.href }
                        : { href: cta.href }

                      return (
                        <Link
                          key={cta.id}
                          ariaLabel={cta.title}
                          as={cta.isCMS ? GatsbyLink : Link}
                          color={cta.color}
                          id={cta.id}
                          mb={2}
                          size={cta.size}
                          {...linkLocation}
                          // {...rest}
                          variant={cta.variant}
                        >
                          <Text variant={['milli', 'pound']}>{cta.text}</Text>
                        </Link>
                      )
                    })}
                  </Stack>
                ) : null}
              </Stack>
            )
          })}
        </Grid>
      ) : null}

      {cta && (
        <Link
          alignSelf="center"
          ariaLabel={cta.title}
          color={cta.color || 'blue'}
          href={cta.href}
          id={cta.id}
          mt={[2, 3, 4]}
          size="wide"
          variant={cta.variant || 'solid'}
        >
          {cta.text}
        </Link>
      )}
    </Section>
  )
}

Spread.propTypes = propTypes
Spread.defaultProps = defaultProps

export { Spread }

export const spreadFragment = graphql`
  fragment SpreadPanel on Panel {
    title {
      ...TitleProps
    }
    theme
    color
    content
    maxColumns
    hasDynamicIcons
    items {
      ...ItemProps
    }
    textOnly
    cta {
      ...CTAProps
    }
  }
`
