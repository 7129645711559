import React from 'react'
import PropTypes from 'prop-types'
import css from '@styled-system/css'
import { Section, Text } from '@rebeldotcom/components'

const propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
}

const visuallyHidden = css`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const A11yHeading = ({ data }) => {
  const { content, id } = data

  return (
    <Section css={visuallyHidden} id={id}>
      <Text as="h1">{content}</Text>
    </Section>
  )
}

A11yHeading.displayName = 'A11yHeading'
A11yHeading.propTypes = propTypes

export { A11yHeading }
