import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import {
  Grid,
  Box,
  Section,
  Text,
  Stack,
  Link,
  Icon,
} from '@rebeldotcom/components'
import { NewsletterSignup, isInternic } from '@rebeldotcom/ui'
import PropTypes from 'prop-types'

const propTypes = {
  data: PropTypes.shape({
    bg: PropTypes.string,
    cta: PropTypes.shape({
      color: PropTypes.string,
      href: PropTypes.string,
      id: PropTypes.string,
      isCMS: PropTypes.bool,
      size: PropTypes.string,
      text: PropTypes.string,
      title: PropTypes.string,
    }),
    id: PropTypes.string.isRequired,
    title: PropTypes.shape({
      main: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }),
  }).isRequired,
}

const NewsletterSubscribe = ({ data }) => {
  const { bg, title, cta, id } = data
  const linkLocation = cta.isCMS ? { to: cta.href } : { href: cta.href }
  return (
    <Section
      bg={bg || 'greys.0'}
      containerProps={{
        maxWidth: 'containers.lg',
      }}
      id={id}
      px={[4, 5]}
      py={[3, 4]}
    >
      <Grid
        alignItems={['center', 'center', 'flex-start']}
        gridColumnGap={3}
        gridRowGap={4}
        gridTemplateColumns={['1fr', '1fr', '66% 33%']}
        gridTemplateRows="auto"
        my={2}
        width="100%"
      >
        <Stack gridRow={[3, 3, 1]}>
          {title?.main?.text ? (
            <Text color="greys.8" variant="teraBold">
              {title.main.text}
            </Text>
          ) : null}
          <Box width="60%">
            <NewsletterSignup id={`${id}`} />
          </Box>
        </Stack>

        <Box borderBottom={2} display={['flex', 'flex', 'none']} />

        <Box alignSelf="center" gridRow={[1]}>
          <Link
            ariaLabel={cta.title}
            as={cta.isCMS ? GatsbyLink : Link}
            color={cta.color}
            id={cta.id}
            size={cta.size}
            variant={cta.id}
            {...linkLocation}
          >
            <Text color="white" mb={2}>
              {cta.text}
            </Text>
            <Box
              alignItems="center"
              color={isInternic ? 'white' : 'pinkLighter'}
            >
              <Text variant="teraBold">{cta.title}</Text>
              <Icon aria-hidden="true" ml={3} name="arrow-right" />
            </Box>
          </Link>
        </Box>
      </Grid>
    </Section>
  )
}

NewsletterSubscribe.propTypes = propTypes

export { NewsletterSubscribe }
