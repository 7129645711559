import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Section,
  Box,
  Grid,
  Stack,
  Heading,
  Link,
  Text,
} from '@rebeldotcom/components'
import { TLDSearchBox, useSessionService } from '@rebeldotcom/ui'
import { Title } from '../../title'
import { containerVariants } from '../../../utilities/panels'

const propTypes = {
  data: PropTypes.shape({
    bg: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    tldSearchConfig: PropTypes.shape({}),
    promoTLDs: PropTypes.arrayOf(
      PropTypes.shape({
        price: PropTypes.shape({}),
      })
    ),
    searchColor: PropTypes.string,
    simpleInfoBoxes: PropTypes.arrayOf(
      PropTypes.shape({
        btnColor: PropTypes.string,
        btnLink: PropTypes.string,
        btnText: PropTypes.string,
        btnVariant: PropTypes.string,
        id: PropTypes.string,
        text: PropTypes.string,
        title: PropTypes.string,
      })
    ),
    theme: PropTypes.oneOf(['light', 'dark']),
    title: PropTypes.shape({
      bottom: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      main: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }),
  }).isRequired,
}

const TLDSearch = ({ data }) => {
  const {
    id,
    bg,
    title,
    searchColor,
    placeholder,
    tldSearchConfig,
    promoTLDs,
    simpleInfoBoxes,
    theme,
  } = data
  const [titleState, setTitle] = useState(title)
  const {
    data: {
      currentCurrency: { code: currentCode },
    },
  } = useSessionService()

  const gridColumns = simpleInfoBoxes ? ['1fr', '1fr', '1f', '3fr 1fr'] : '1fr'

  useEffect(() => {
    setTitle({
      ...titleState,
      bottom: { text: titleState.bottom.text + currentCode.toUpperCase() },
    })
  }, [currentCode])

  const tldPrices = useStaticQuery(graphql`
    query {
      allDynamicPrice(filter: { isTld: { eq: true } }) {
        nodes {
          name
          prices {
            AUD {
              regular
              renew
            }
            CAD {
              regular
              renew
            }
            MXN {
              regular
              renew
            }
            USD {
              regular
              renew
            }
          }
        }
      }
    }
  `)

  return (
    <Section
      bg={bg || containerVariants[theme || 'light'].bg}
      color={containerVariants[theme || 'light'].text}
      id={id}
      pb={5}
      pt={4}
      px={[3, 4]}
    >
      <Box maxWidth="containers.xl" width="100%">
        <Title
          alignment={['center', 'left', 'left']}
          as="h1"
          lineHeight={['1.3']}
          maxWidth="100%"
          mb={4}
          mt={4}
          theme={theme}
          title={titleState}
          variant={['teraBold', 'petaBold', 'exaBold', 'zettaBold']}
          width="100%"
        />
      </Box>
      <Grid
        gridGap={3}
        gridTemplateColumns={gridColumns}
        maxWidth="containers.xl"
        width="100%"
      >
        <Stack width="100%">
          <TLDSearchBox
            allTldPrices={tldPrices.allDynamicPrice.nodes}
            id={`${id}-box`}
            placeholder={placeholder}
            promoTLDs={promoTLDs}
            searchColor={searchColor}
            tldSearchConfig={tldSearchConfig}
          />
        </Stack>
        <Stack gridGap={3}>
          {simpleInfoBoxes &&
            simpleInfoBoxes.map(infoBox => {
              return (
                <Stack key={infoBox.id} bg="white" border="light" p={4}>
                  <Heading textAlign="center" variant="teraBold">
                    {infoBox.title}
                  </Heading>
                  <Text textAlign="center">{infoBox.text} </Text>
                  <Link
                    color={infoBox.btnColor}
                    href={infoBox.btnLink}
                    mt={3}
                    variant={infoBox.btnVariant}
                  >
                    {infoBox.btnText}
                  </Link>
                </Stack>
              )
            })}
        </Stack>
      </Grid>
    </Section>
  )
}

TLDSearch.propTypes = propTypes

export { TLDSearch }

export const tldSearchFragment = graphql`
  fragment TldSearchPanel on Panel {
    id
    bg
    title {
      ...TitleProps
    }
    placeholder
    tldSearchConfig {
      popularTlds
      newestTlds
      onSaleTlds
    }
    promoTLDs {
      ...PromoTLDsProps
    }
    searchColor
    simpleInfoBoxes {
      ...SimpleInfoBoxProps
    }
  }
`
