import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Section, Stack } from '@rebeldotcom/components'
import { TrustPilotWidget } from '@rebeldotcom/ui'
import { Title } from '../../title'

import { containerVariants } from '../../../utilities/panels'

const propTypes = {
  data: PropTypes.shape({
    bg: PropTypes.string,
    id: PropTypes.string.isRequired,
    theme: PropTypes.oneOf(['light', 'dark']),
    title: PropTypes.shape({
      bottom: PropTypes.string,
      main: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      top: PropTypes.shape({
        bgColor: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }),
    trustpilotTemplateId: PropTypes.string,
  }).isRequired,
}

const TrustPilot = ({ data }) => {
  const { trustpilotTemplateId, title, bg, theme = 'light', id } = data

  return (
    <Section
      bg={bg || containerVariants[theme || 'light'].bg}
      id={id}
      py={[4, 5]}
    >
      <Stack maxWidth="containers.lg">
        <Title mb={4} mx={3} theme={theme} title={title} />
        <TrustPilotWidget
          height="130px"
          id="trustPilotPanel"
          templateId={trustpilotTemplateId}
          theme={theme}
          width="100%"
        />
      </Stack>
    </Section>
  )
}

TrustPilot.propTypes = propTypes

export { TrustPilot }

export const trustPilotFragment = graphql`
  fragment TrustPilotPanel on Panel {
    title {
      ...TitleProps
    }
    trustpilotTemplateId
  }
`
