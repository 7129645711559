import React from 'react'
import { PropTypes } from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import {
  Box,
  Stack,
  Link,
  theme as systemTheme,
  Text,
  Section,
  Button,
} from '@rebeldotcom/components'
import { InfoCollector } from '@rebeldotcom/ui'
import { ProductFeatureCard } from '../../compound/product-feature-card'
import { Content } from '../../content'
import { Title } from '../../title'
import Image from '../../image'
import YoutubeEmbed from '../../youtube-embed'
import {
  containerVariants,
  verticalAlignment,
  getImageProps,
  getSectionSize,
  handleCTAClick,
} from '../../../utilities/panels'

const { media } = systemTheme

const propTypes = {
  data: PropTypes.shape({
    alignment: PropTypes.oneOf(['left', 'right']),
    bg: PropTypes.string,
    color: PropTypes.string,
    columns: PropTypes.number,
    content: PropTypes.string,
    ctas: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string,
        href: PropTypes.string,
        id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['link', 'addToCart']),
        variant: PropTypes.string,
      })
    ),
    fineprint: PropTypes.string,
    id: PropTypes.string.isRequired,
    infoCollector: PropTypes.object,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        subtitle: PropTypes.string,
        title: PropTypes.shape({
          main: PropTypes.shape({
            text: PropTypes.string,
            textColor: PropTypes.string,
          }),
        }),
      })
    ),
    mainContent: PropTypes.string,
    mainTitle: PropTypes.shape({
      main: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }),
    maxWidth: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    product: PropTypes.object.isRequired,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    theme: PropTypes.oneOf(['light', 'dark']),
    title: PropTypes.shape({
      bottom: PropTypes.string,
      main: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      top: PropTypes.shape({
        bgColor: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }),
    vertAlignment: PropTypes.string,
  }).isRequired,
  variant: PropTypes.oneOf(['old', 'new']),
}

const defaultProps = {
  variant: 'old',
}

const gridColumns = {
  1: '1fr',
  2: '1fr 1fr',
}

const CTASplit = props => {
  const {
    data: {
      id,
      bg,
      mainTitle,
      mainContent,
      color,
      columns = 2,
      content,
      ctas,
      title,
      product,
      theme,
      maxWidth = 'md',
      alignment,
      vertAlignment,
      fineprint,
      size,
      infoCollector,
    },
  } = props

  const contentAlignmentCss = media.mediumAndDown`
    & {
      div,
      span {
        text-align: center;
      }
    }
  `
  const contentAlignment = alignment === 'left' ? 'row' : 'row-reverse'
  const getMaxWidth = () => {
    return maxWidth ? { maxWidth: `containers.${maxWidth}` } : {}
  }

  const gridTemplateColumns = ['1fr', gridColumns[columns]]

  const packageMargin =
    alignment === 'left' ? { mt: [4, 4, 0] } : { mb: [4, 4, 0] }

  return (
    <Section
      bg={bg || containerVariants[theme || 'light'].bg}
      color={containerVariants[theme || 'light'].text}
      containerProps={{
        maxWidth: 'containers.lg',
      }}
      id={id}
      px={[1, 4]}
      py={[3, 3, 5]}
    >
      {mainTitle || mainContent ? (
        <Stack mb={4}>
          {mainTitle && (
            <Title
              lineHeight={['1.2']}
              theme={theme}
              title={mainTitle}
              variant={['gigaBold', 'petaBold', 'exaBold', 'exaBold']}
            />
          )}

          {mainContent && (
            <Content
              maxWidth="containers.md"
              mt={3}
              textAlign="center"
              variant="mega"
            >
              {mainContent}
            </Content>
          )}
        </Stack>
      ) : null}
      <Box bg={bg} justifyContent="center" width="100%">
        <Box
          alignItems="center"
          flex="1"
          flexDirection={['column-reverse', 'column-reverse', contentAlignment]}
          maxWidth="containers.lg"
          {...getMaxWidth()}
          pb={[3, 0, 0, 0]}
          pt={[3, 3, 3, 0]}
        >
          <Stack
            alignItems={['center', 'center', 'flex-start', 'flex-start']}
            flex={['1', '1', '0 1 50%', '0 0 50%']}
            justifyContent={verticalAlignment[vertAlignment] || 'flex-start'}
            px={[3, 4, 5]}
            py={4}
          >
            <Title
              alignment={['center', 'center', 'left', 'left']}
              as={mainTitle ? 'div' : 'h2'}
              color={containerVariants[theme || 'light'].text}
              lineHeight={['1.3']}
              mb={4}
              theme={theme}
              title={title}
            />
            <Stack
              alignItems={['center', 'center', 'flex-start', 'flex-start']}
              maxWidth={`containers.${maxWidth}`}
            >
              {content && (
                <Content
                  color={color}
                  htmlCss={contentAlignmentCss}
                  theme={theme}
                  variant="mega"
                >
                  {content}
                </Content>
              )}
              <Box alignItems="center" flexWrap="wrap" maxWidth="50rem" mt={1}>
                {ctas &&
                  ctas.map((cta, idx) => {
                    if (cta.type === 'addToCart') {
                      return (
                        <Button
                          key={cta.id}
                          ariaLabel={cta.title || cta.text}
                          color={cta.color}
                          id={cta.id}
                          onClick={() => handleCTAClick(cta)}
                          rel="nofollow"
                          variant={cta.variant}
                        >
                          {cta.text}
                        </Button>
                      )
                    }

                    const linkLocation = cta.isCMS
                      ? { to: cta.href }
                      : { href: cta.href }

                    return (
                      <Link
                        key={idx}
                        ariaLabel={cta.title}
                        as={cta.isCMS ? GatsbyLink : Link}
                        color={cta.color}
                        id={cta.id}
                        mr={3}
                        mt={4}
                        size={cta.size}
                        {...linkLocation}
                        variant={cta.variant}
                      >
                        <Text variant={['milli', 'pound']}>{cta.text}</Text>
                      </Link>
                    )
                  })}
                {infoCollector && (
                  <Box mt={4}>
                    <InfoCollector
                      btnProps={{
                        color: infoCollector.btnProps?.color || 'blueDark',
                        variant: infoCollector.btnProps?.variant || 'solid',
                      }}
                      buttonText={infoCollector.buttonText}
                      errorMessage={infoCollector.errorMessage}
                      fields={infoCollector.fields}
                      source={infoCollector.source}
                      successMessage={infoCollector.successMessage}
                      title={infoCollector.title}
                    />
                  </Box>
                )}
              </Box>
            </Stack>
            {fineprint && (
              <Box justifyContent="flex-start">
                <Text
                  fontSize={1}
                  lineHeight="title"
                  maxWidth="50rem"
                  mt={4}
                  textAlign={['center', alignment]}
                >
                  {fineprint}
                </Text>
              </Box>
            )}
          </Stack>
          {product?.image && (
            <Box
              bg={bg}
              justifyContent="center"
              position="relative"
              {...getSectionSize(size)}
              maxWidth={['35rem', '55rem', '55rem', '100%']}
              width={['100%', '100%', '50%']}
            >
              <Image
                alt={product.image.altText}
                height="100%"
                objectFit="contain"
                style={{
                  position: 'absolute',
                }}
                top="0px"
                width="100%"
                {...getImageProps(product.image)}
              />
            </Box>
          )}
          {product?.features && (
            <Box
              alignItems="flex-start"
              // height={['auto', 'auto', '100%']}
              justifyContent="center"
              pb={[4, 0]}
              width={[1, 1, 1.3 / 3]}
              {...packageMargin}
            >
              <ProductFeatureCard maxWidth="35rem" {...product} />
            </Box>
          )}
          {product?.video && (
            <Box
              alignItems="flex-start"
              // height={['auto', 'auto', '100%']}
              justifyContent="center"
              pb={[4, 0]}
              width={[1, 1, 1.3 / 3]}
              {...packageMargin}
            >
              <YoutubeEmbed embedId={product.video} />
            </Box>
          )}
        </Box>
      </Box>
    </Section>
  )
}

CTASplit.propTypes = propTypes
CTASplit.defaultProps = defaultProps
CTASplit.displayName = 'CTASplit'
export { CTASplit }
