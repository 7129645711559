import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Section, Box, Text, Icon } from '@rebeldotcom/components'
import { DomainSearchBar, useSessionService, Price } from '@rebeldotcom/ui'
import { containerVariants } from '../../../utilities/panels'

const propTypes = {
  data: PropTypes.shape({
    bg: PropTypes.string,
    btnAriaLabel: PropTypes.string,
    btnColor: PropTypes.string,
    btnText: PropTypes.string,
    bulkSearch: PropTypes.bool,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    promoTLDs: PropTypes.arrayOf(
      PropTypes.shape({
        price: PropTypes.shape({}),
      })
    ),
    promoTLDsDisplay: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    theme: PropTypes.oneOf(['light', 'dark']),
  }).isRequired,
}

const SimpleSearch = ({ data }) => {
  const {
    id,
    bg,
    btnAriaLabel,
    btnColor,
    btnText,
    placeholder,
    bulkSearch,
    promoTLDs,
    promoTLDsDisplay,
    theme,
  } = data

  const {
    data: {
      currentCurrency: { code: currentCode },
    },
  } = useSessionService()

  return (
    <Section
      bg={bg || containerVariants[theme || 'light'].bg}
      color={containerVariants[theme || 'light'].text}
    >
      <Box
        flexDirection={['column', 'column', 'row']}
        justifyContent="center"
        maxWidth={['containers.sm', 'containers.sm', 'containers.lg']}
        padding={2}
        width="100%"
      >
        <Box flex={['0', '0', '1 1 50%']} justifyContent="center">
          <DomainSearchBar
            bordered={false}
            btnAriaLabel={btnAriaLabel}
            btnColor={btnColor}
            btnText={btnText}
            bulkSearch={bulkSearch}
            id={`${id}-input`}
            placeholder={placeholder}
            theme={theme}
          />
        </Box>
        <Box
          alignItems="center"
          display={promoTLDsDisplay || ['flex', 'flex', 'flex']}
          flex="1 1 50%"
          flexWrap="wrap"
          justifyContent="space-around"
          mx={[0, 0, 4]}
        >
          {promoTLDs.map(tld => (
            <Box
              key={tld.id}
              alignItems="center"
              alignSelf="center"
              justifyContent="center"
              mb={[3, 3, 0]}
            >
              {tld.text ? <Text variant="gigaBold">{tld.text}</Text> : null}
              {tld.icon ? (
                <Icon
                  height="2rem"
                  name={tld.icon.name}
                  title={tld.icon.name}
                  titleId={tld.icon.name}
                  width={tld.icon.width || '5rem'}
                />
              ) : null}
              {tld.price ? (
                <Text ml={[2, 2, 3]} variant="mega">
                  {`${Price.getDisplayPrice(
                    tld.price.dynamicPrice.prices,
                    currentCode.toUpperCase()
                  )}`}
                </Text>
              ) : null}
            </Box>
          ))}
        </Box>
      </Box>
    </Section>
  )
}

SimpleSearch.propTypes = propTypes

export { SimpleSearch }

export const simpleSearchFragment = graphql`
  fragment SimpleSearchPanel on Panel {
    bg
    btnAriaLabel
    btnColor
    btnText
    bulkSearch
    id
    placeholder
    promoTLDs {
      ...PromoTLDsProps
    }
    promoTLDsDisplay
  }
`
