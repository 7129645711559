import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Link, Text, Box, Section, Grid, Stack } from '@rebeldotcom/components'
import { Title } from '../../title'
import { Content } from '../../content'
import Img from '../../image'
import {
  containerVariants,
  getImageProps,
  handleClick,
} from '../../../utilities/panels'

const propTypes = {
  data: PropTypes.shape({
    bg: PropTypes.string,
    content: PropTypes.string,
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.object,
        title: PropTypes.shape({
          main: PropTypes.shape({
            text: PropTypes.string,
            textColor: PropTypes.string,
          }),
        }),
      })
    ).isRequired,
    maxColumns: PropTypes.number,
    theme: PropTypes.oneOf(['light', 'dark']),
    title: PropTypes.shape({
      bottom: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      main: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      top: PropTypes.shape({
        bgColor: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }),
  }).isRequired,
  variant: PropTypes.oneOf(['old', 'new']),
}

const defaultProps = {
  variant: 'old',
}

const WordpressThemes = ({ data }) => {
  const { title, content, items, theme, maxColumns, bg, id } = data
  const count = (items && items.length) || 0
  const rowCols = Math.min(4, count)
  const mobileGrid = '1fr'
  const smallGrid =
    count % 2 === 0 ? 'repeat(2, 1fr)' : `repeat(${rowCols}, 1fr)`
  const mediumGrid =
    count % 3 === 0 ? 'repeat(3, 1fr)' : `repeat(${rowCols}, 1fr)`
  const largeGrid = `repeat(${Math.min(maxColumns, rowCols)}, 1fr)`

  return (
    <Section
      bg={bg || containerVariants[theme || 'light'].bg}
      color={containerVariants[theme || 'light'].text}
      containerProps={{
        maxWidth: 'containers.lg',
      }}
      id={id}
      px={[4]}
      py={[3, 3, 4]}
    >
      {title || content ? (
        <Stack maxWidth="containers.sm" mb={4}>
          {title && (
            <Title
              lineHeight={['1.3']}
              theme={theme}
              title={title}
              variant={['teraBold', 'petaBold']}
            />
          )}

          {content && (
            <Content
              maxWidth="containers.md"
              mt={3}
              textAlign="center"
              variant="mega"
            >
              {content}
            </Content>
          )}
        </Stack>
      ) : null}

      {items && items.length > 0 ? (
        <Grid
          gridGap={[3, 5]}
          gridTemplateColumns={[mobileGrid, smallGrid, mediumGrid, largeGrid]}
          justifyContent="center"
          maxWidth={['containers.sm', 'containers.md', 'containers.lg']}
          mb={3}
          mt={3}
        >
          {items.map((item, idx) => {
            return (
              <Stack key={idx} p={1}>
                <Box
                  alignItems="center"
                  flexDirection="column"
                  justifyContent="center"
                  width={1}
                >
                  {item.title?.main && (
                    <Text
                      fontFamily="title"
                      fontSize={3}
                      fontWeight="semi"
                      py={2}
                    >
                      {item.title.main.text}
                    </Text>
                  )}
                  {item.image ? (
                    <Box border={2}>
                      <Link
                        href={item.href}
                        id={item.id}
                        onClick={e => handleClick(e, item.image.src)}
                        target="_blank"
                      >
                        <Img
                          alt={item.image.altText}
                          id={idx}
                          width={item.image.width || '30rem'}
                          {...getImageProps(item.image)}
                        />
                      </Link>
                    </Box>
                  ) : null}
                </Box>
              </Stack>
            )
          })}
        </Grid>
      ) : null}
    </Section>
  )
}

WordpressThemes.propTypes = propTypes
WordpressThemes.defaultProps = defaultProps

export { WordpressThemes }

export const wordpressThemesFragment = graphql`
  fragment WordPressThemesPanel on Panel {
    bg
    maxColumns
    theme
    items {
      ...ItemProps
    }
  }
`
