import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link as GatsbyLink } from 'gatsby'
import {
  Box,
  Icon,
  Link,
  Button,
  Text,
  Section,
  Grid,
  Stack,
  theme as systemTheme,
} from '@rebeldotcom/components'
import {
  useSessionService,
  InfoCollector,
  Price,
  SaleBox,
  PriceBox,
} from '@rebeldotcom/ui'
import Img from '../../image'
import { Title } from '../../title'
import {
  containerVariants,
  getImageProps,
  handleCTAClick,
} from '../../../utilities/panels'
import { Content } from '../../content'

const INVERTED = 'inverted'
const CALLOUT = 'callout'
const DEFAULT = 'default'

const { colors } = systemTheme

const propTypes = {
  data: PropTypes.shape({
    bg: PropTypes.string,
    content: PropTypes.string,
    fineprint: PropTypes.string,
    id: PropTypes.string.isRequired,
    infoCollector: PropTypes.object,
    maxColumns: PropTypes.number,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        ctas: PropTypes.arrayOf(
          PropTypes.shape({
            color: PropTypes.string,
            href: PropTypes.string,
            id: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['link', 'addToCart']),
            variant: PropTypes.string,
          })
        ),
        icon: PropTypes.shape({
          color: PropTypes.string,
          name: PropTypes.string.isRequired,
          width: PropTypes.string,
        }),
        panelBadge: PropTypes.shape({
          backgroundColor: PropTypes.isRequired,
          color: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        }),
        price: PropTypes.object,
        title: PropTypes.shape({
          bottom: PropTypes.shape({
            text: PropTypes.string,
            textColor: PropTypes.string,
          }),
          main: PropTypes.shape({
            text: PropTypes.string,
            textColor: PropTypes.string,
          }),
          top: PropTypes.shape({
            bgColor: PropTypes.string,
            text: PropTypes.string,
            textColor: PropTypes.string,
          }),
        }),
        variant: PropTypes.string,
      })
    ).isRequired,
    theme: PropTypes.oneOf(['light', 'dark']),
    title: PropTypes.shape({
      bottom: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      main: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      top: PropTypes.shape({
        bgColor: PropTypes.string,
        highlight: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }),
  }).isRequired,
}

const LargeProductGrid = ({ data }) => {
  const {
    data: {
      currentCurrency: { code: currentCode },
    },
  } = useSessionService()
  const {
    title,
    content,
    products,
    theme,
    maxColumns,
    id,
    bg,
    infoCollector,
    fineprint,
  } = data

  const count = products.length

  const mobileGrid = '1fr'
  const smallGrid = count % 2 === 0 ? 'repeat(2, 1fr)' : '1fr'
  const mediumGrid = count % 3 === 0 ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)'
  const largeGrid = `repeat(${maxColumns || 4}, 1fr)`

  const renderPrice = product => {
    if (!product.price) return null

    if (product.price.dynamicPrice?.prices) {
      return (
        <Box>
          <Price
            currencyCode={currentCode.toUpperCase()}
            price={{ ...product.price, ...product.price.dynamicPrice?.prices }}
            variant={product.variant}
          />
        </Box>
      )
    }

    return (
      <Box>
        <Price
          currencyCode={currentCode}
          price={product.price}
          variant={product.variant}
        />
      </Box>
    )
  }

  const renderPriceBox = product => {
    if (!product.price) return null

    if (product.price.dynamicPrice?.prices) {
      return (
        <Box>
          <PriceBox
            currencyCode={currentCode.toUpperCase()}
            price={{ ...product.price, ...product.price.dynamicPrice?.prices }}
            variant={product.variant}
          />
        </Box>
      )
    }

    return (
      <Box>
        <PriceBox
          currencyCode={currentCode}
          price={product.price}
          variant={product.variant}
        />
      </Box>
    )
  }

  const renderIcon = product => {
    return product.icon ? (
      <Icon
        color={product.icon?.color}
        height="4rem"
        mb={4}
        name={product.icon.name}
        title={product.title.main.text}
        width={product.icon.width || '4rem'}
      />
    ) : null
  }

  const renderImage = product => {
    return product.image ? (
      <Img
        alt={product.image.altText}
        height="5rem"
        my={3}
        width={product.image.width || '5rem'}
        {...getImageProps(product.image)}
      />
    ) : null
  }

  const renderTitle = product => {
    return product.title ? (
      <Title
        alignment="left"
        title={product.title}
        variant={['megaBold', 'gigaBold']}
      />
    ) : null
  }

  const renderContent = product => {
    return product.content ? (
      <Content mt={3} variant="pound">
        {product.content}
      </Content>
    ) : null
  }

  const renderCTAS = product => {
    return product.ctas && product.ctas.length > 0 ? (
      <Stack alignItems="center" flexWrap="wrap" mt={4}>
        {product.ctas &&
          product.ctas.map(cta => {
            if (cta.type === 'addToCart') {
              return (
                <Button
                  key={cta.id}
                  ariaLabel={cta.title || cta.text}
                  color={cta.color}
                  id={cta.id}
                  onClick={() =>
                    handleCTAClick(cta, product.price, currentCode)
                  }
                  rel="nofollow"
                  variant={cta.variant}
                  width="100%"
                >
                  {cta.text}
                </Button>
              )
            }

            const linkLocation = cta.isCMS
              ? { to: cta.href }
              : { href: cta.href }

            return (
              <Link
                key={cta.id}
                ariaLabel={cta.title}
                as={cta.isCMS ? GatsbyLink : Link}
                color={cta.color}
                id={cta.id}
                size={cta.size}
                {...linkLocation}
                variant={cta.variant}
                width="100%"
              >
                <Text variant={['milli', 'pound']}>{cta.text}</Text>
              </Link>
            )
          })}
      </Stack>
    ) : null
  }

  return (
    <Section
      bg={bg || containerVariants[theme || 'light'].bg}
      color={containerVariants[theme || 'light'].text}
      containerProps={{
        maxWidth: 'containers.lg',
      }}
      id={id}
      px={[3, 4]}
      py={[5]}
    >
      {title ? (
        <Title
          as="h2"
          lineHeight="title"
          theme={theme}
          title={title}
          variant={['teraBold', 'petaBold']}
        />
      ) : null}

      {content ? (
        <Content
          maxWidth="containers.sm"
          mt={4}
          textAlign="center"
          variant="mega"
        >
          {content}
        </Content>
      ) : null}

      <Grid
        gridGap={2}
        gridTemplateColumns={[mobileGrid, smallGrid, mediumGrid, largeGrid]}
        justifyContent="center"
        maxWidth="120rem"
        mt={[5]}
      >
        {products.map((product, idx) => {
          return (
            <Stack
              key={idx}
              border={`1px solid ${
                product.panelBadge
                  ? product.panelBadge.backgroundColor
                  : colors.greys[6]
              }`}
              mt={product.panelBadge ? [0, -4] : 0}
            >
              {product.panelBadge && (
                <Box
                  bg={product.panelBadge.backgroundColor}
                  justifyContent="center"
                  width="100%"
                >
                  <Text color={product.panelBadge.color} variant="gigaBold">
                    {product.panelBadge.title}
                  </Text>
                </Box>
              )}
              <Stack
                alignItems="flex-start"
                as={Text}
                bg={product.bg || ''}
                height="100%"
                lineHeight="body"
                px={4}
                py={4}
              >
                {product.variant && product.variant === INVERTED && (
                  <>
                    {renderIcon(product)}
                    {renderImage(product)}
                    {renderTitle(product)}
                    {product.price ||
                    (product.ctas && product.ctas.length > 0) ? (
                      <Stack>
                        {renderPrice(product)}
                        {renderCTAS(product)}
                      </Stack>
                    ) : null}
                    {renderContent(product)}
                  </>
                )}
                {product.variant && product.variant === CALLOUT && (
                  <>
                    <Box mb={3}>
                      <SaleBox price={product.price} />
                    </Box>

                    {renderIcon(product)}
                    {renderImage(product)}
                    {renderTitle(product)}
                    {product.price ||
                    (product.ctas && product.ctas.length > 0) ? (
                      <Stack>
                        {renderPriceBox(product)}
                        {renderCTAS(product)}
                      </Stack>
                    ) : null}
                    {renderContent(product)}
                  </>
                )}
                {(!product.variant || product.variant === DEFAULT) && (
                  <>
                    {renderIcon(product)}
                    {renderImage(product)}
                    {renderTitle(product)}
                    {renderContent(product)}
                    {product.price ||
                    (product.ctas && product.ctas.length > 0) ? (
                      <Stack
                        alignItems="stretch"
                        alignSelf="stretch"
                        flex="1"
                        justifyContent="flex-end"
                      >
                        {renderPrice(product)}
                        {renderCTAS(product)}
                      </Stack>
                    ) : null}
                  </>
                )}
              </Stack>
            </Stack>
          )
        })}
      </Grid>

      {infoCollector && (
        <Box mt={4} width={[1 / 4]}>
          <InfoCollector
            btnProps={{
              color: infoCollector.btnProps?.color || 'blueDark',
              variant: infoCollector.btnProps?.variant || 'solid',
            }}
            buttonText={infoCollector.buttonText}
            errorMessage={infoCollector.errorMessage}
            fields={infoCollector.fields}
            source={infoCollector.source}
            successMessage={infoCollector.successMessage}
            title={infoCollector.title}
          />
        </Box>
      )}

      {fineprint && (
        <Content
          color={containerVariants[theme || 'light'].text}
          fontSize={1}
          maxWidth="container.md"
          mt={4}
        >
          {fineprint}
        </Content>
      )}
    </Section>
  )
}

LargeProductGrid.propTypes = propTypes

export { LargeProductGrid }

export const largeProductGridFragment = graphql`
  fragment LargeProductGridPanel on Panel {
    title {
      ...TitleProps
    }
    theme
    content
    maxColumns
    products {
      bg
      icon {
        name
        width
        color
      }
      image {
        ...ImageProps
      }
      title {
        ...TitleProps
      }
      content
      price {
        ...PriceProps
      }
      ctas {
        ...CTAProps
      }
      href
      variant
      panelBadge {
        ...PanelBadgeProps
      }
    }
    highlight {
      id
      bg
      title {
        ...TitleProps
      }
      content
      href
    }
  }
`
