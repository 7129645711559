import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Button, Icon, Stack, theme } from '@rebeldotcom/components'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { Content } from '../content'
import { Title } from '../title'

const { colors } = theme

const propTypes = {
  bg: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      title: PropTypes.shape({
        main: PropTypes.shape({
          text: PropTypes.string,
          textColor: PropTypes.string,
        }),
      }).isRequired,
    })
  ).isRequired,
}

const defaultPropTypes = {
  bg: 'white',
}

const ContentPane = motion.custom(styled.div`
  overflow: hidden;
  background-color: ${({ bg }) => colors[bg]};
  width: 100%;
`)

const IconBox = motion.custom(styled.div``)

const iconBoxVariants = {
  open: { rotate: 180 },
  closed: { rotate: 0 },
}

const buttonStyles = css`
  border: none;
  outline: none;
  width: 100%;
  transition: background-color 0.2s ease;
  background-color: ${({ bg }) => colors[bg]};
  color: ${colors.black};
  &:hover,
  &:active {
    background-color: ${colors.greyLightest};
    color: ${colors.black};
  }
  &:focus {
    background-color: ${colors.greyLight};
  }

  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const itemPropTypes = {
  bg: PropTypes.string,
  content: PropTypes.string.isRequired,
  title: PropTypes.shape({
    main: PropTypes.shape({
      text: PropTypes.string,
      textColor: PropTypes.string,
    }),
  }).isRequired,
}

const AccordionItem = ({ title, content, bg, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  const paneVariants = {
    visible: { opacity: 1, height: 'auto' },
    hidden: { opacity: 0, height: '0px' },
  }

  return (
    <Stack justifyContent="center" width="100%" {...rest}>
      <Button bg={bg} css={buttonStyles} onClick={toggleAccordion}>
        <Grid justifyContent="flex-start" width={1}>
          <Title alignment="left" title={title} variant="giga" />
        </Grid>
        <IconBox
          animate={isOpen ? 'open' : 'closed'}
          justifyContent="flex-end"
          variants={iconBoxVariants}
        >
          <Icon
            height="3rem"
            ml="auto"
            name="arrow-down"
            title="back"
            width="4rem"
          />
        </IconBox>
      </Button>

      <ContentPane
        animate={isOpen ? 'visible' : 'hidden'}
        bg={bg}
        overflow="hidden"
        transition={{ opacity: { duration: 0.1 }, height: { duration: 0.2 } }}
        variants={paneVariants}
        width="100%"
      >
        <Content fontSize={1} padding="2rem">
          {content}
        </Content>
      </ContentPane>
    </Stack>
  )
}

const StructuredData = ({ items }) => {
  const structuredDataItems = items.map(({ title, content }) => {
    return {
      '@type': 'Question',
      name: title.main.text,
      acceptedAnswer: {
        '@type': 'Answer',
        text: content,
      },
    }
  })
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: structuredDataItems,
  }
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
    ></script>
  )
}

AccordionItem.propTypes = itemPropTypes
AccordionItem.defaultProps = defaultPropTypes

const Accordion = ({ items, bg, ...rest }) => {
  return (
    <Stack {...rest} justifyContent="center" maxWidth="containers.md" mt={4}>
      {items &&
        items.map(({ title, content }) => {
          return (
            <AccordionItem
              key={title.main.text}
              bg={bg}
              content={content}
              title={title}
            />
          )
        })}

      <StructuredData items={items} />
    </Stack>
  )
}

Accordion.propTypes = propTypes
Accordion.defaultProps = defaultPropTypes

export { Accordion }
