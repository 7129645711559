import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import {
  Box,
  Text,
  Icon,
  Link,
  Badge,
  Stack,
  Button,
} from '@rebeldotcom/components'
import { Price, useSessionService } from '@rebeldotcom/ui'
import { handleCTAClick } from '../../utilities/panels'

const propTypes = {
  badge: PropTypes.shape({
    color: PropTypes.string,
    text: PropTypes.string,
  }),
  bordered: PropTypes.bool,
  content: PropTypes.string,
  copy: PropTypes.string,
  ctas: PropTypes.arrayOf(
    PropTypes.shape({
      addToCartPath: PropTypes.string,
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
      href: PropTypes.string,
      id: PropTypes.string.isRequired,
      itemCode: PropTypes.string,
      itemDescription: PropTypes.string,
      product: PropTypes.string,
      productCategory: PropTypes.string,
      returnPath: PropTypes.string,
      size: PropTypes.string,
      source: PropTypes.string,
      text: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.string,
      variant: PropTypes.string,
    })
  ),
  description: PropTypes.string,
  discount: PropTypes.string,
  features: PropTypes.object.isRequired,
  price: PropTypes.object.isRequired,
  renewal: PropTypes.string,
  title: PropTypes.object.isRequired,
}

const defaultProps = {
  badge: null,
  bordered: false,
  content: null,
  copy: null,
  ctas: null,
  description: null,
  discount: null,
  renewal: null,
}

const ProductFeatureCard = ({
  title,
  description,
  discount,
  badge,
  features,
  price,
  renewal,
  ctas,
  content,
  copy,
  bordered,
  ...rest
}) => {
  const {
    data: {
      currentCurrency: { code: currentCode },
    },
  } = useSessionService()

  const featureList = features
    ? Object.keys(features).reduce((list, key) => {
        return features[key] ? [...list, features[key]] : list
      }, [])
    : []

  const borders = bordered ? { borderRight: 2, borderTop: 2 } : {}

  return (
    <Stack position="relative" {...rest} mt={4}>
      {badge && (
        <Box height="3rem" position="absolute" s top="-3rem" width="100%">
          <Badge bg="reds.0" flex="1" fontWeight="bold" position="relative">
            <Box
              bg="reds.0"
              bottom="-.5rem"
              height="2rem"
              position="absolute"
              transform="rotate(45deg)"
              width="2rem"
              zIndex="1"
            />
            <Box bg="reds.0" zIndex="2">
              {badge.text}
            </Box>
          </Badge>
        </Box>
      )}

      <Stack
        bg="greys.8"
        {...borders}
        flex="1"
        justifyContent="space-between"
        pb={4}
        pt={3}
        px={[4, 4, 3, 4]}
      >
        <Stack>
          <Box alignItems="center">
            <Text fontSize={3} fontWeight="semi">
              {title.main}
            </Text>
          </Box>
          {description ? (
            <Box alignItems="center" my={3}>
              <Text fontSize={1} fontWeight="light">
                {description}
              </Text>
            </Box>
          ) : null}
          {price ? (
            <Box>
              <Price currencyCode={currentCode} price={price} />
            </Box>
          ) : null}
          {discount ? (
            <Box py={1}>
              <Text bg="yellows.5" fontSize={0} fontWeight="bold">
                {discount}
              </Text>
            </Box>
          ) : null}
          {renewal ? (
            <Box height={32} mb={3} py={1}>
              <Text fontSize={0} fontWeight="bold">
                {renewal}
              </Text>
            </Box>
          ) : null}

          {ctas && ctas.length > 0 ? (
            <Stack mt={1}>
              {ctas.map(cta => {
                if (cta.type === 'addToCart') {
                  return (
                    <Button
                      key={cta.id}
                      ariaLabel={cta.title || cta.text}
                      color={cta.color}
                      id={cta.id}
                      onClick={() => handleCTAClick(cta, price, currentCode)}
                      rel="nofollow"
                      variant={cta.variant}
                    >
                      {cta.text}
                    </Button>
                  )
                }

                return (
                  <Link
                    key={cta.id}
                    alignSelf="center"
                    ariaLabel={cta.title}
                    as={cta.isCMS ? GatsbyLink : Link}
                    color={cta.color}
                    href={cta.href}
                    id={cta.id}
                    mr={[0, 0, 2]}
                    size={cta.size}
                    variant={cta.variant}
                  >
                    {cta.text}
                  </Link>
                )
              })}
            </Stack>
          ) : null}
          {featureList && featureList.length > 0 ? (
            <Stack mb={2} mt={3}>
              {featureList.map((feature, id) => {
                return (
                  <Box key={id} mb={2}>
                    <Icon color="blueDark" mr={2} name="checkmark-outline" />
                    <Text variant="milli">{feature}</Text>
                  </Box>
                )
              })}
            </Stack>
          ) : null}
          {content ? (
            <Box pt={2}>
              <Text>{content}</Text>
            </Box>
          ) : null}
        </Stack>

        {copy && (
          <Text mt={2} textAlign="center" variant="micro">
            {copy}
          </Text>
        )}
      </Stack>
    </Stack>
  )
}

ProductFeatureCard.propTypes = propTypes
ProductFeatureCard.defaultProps = defaultProps

export { ProductFeatureCard }
