import React from 'react'
import PropTypes from 'prop-types'
import { Box, Icon, Text, Grid } from '@rebeldotcom/components'
import { Title } from '../../title'
import { containerVariants } from '../../../utilities/panels'

const propTypes = {
  data: PropTypes.shape({
    alignment: PropTypes.oneOf(['left', 'center', 'right']),
    bg: PropTypes.string,
    columns: PropTypes.number,
    image: PropTypes.shape({
      altText: PropTypes.string,
      path: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.shape({}),
        }),
      }),
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        title: PropTypes.shape({
          main: PropTypes.shape({
            text: PropTypes.string,
            textColor: PropTypes.string,
          }),
        }),
      })
    ),
    theme: PropTypes.oneOf(['light', 'dark']),
    title: PropTypes.shape({
      bottom: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      main: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      top: PropTypes.shape({
        bgColor: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }),
  }).isRequired,
}

const gridColumns = {
  1: '1fr',
  2: '1fr 1fr 1fr',
}

const GridList = ({ data }) => {
  const { bg, title, items, theme, alignment = 'right', columns = 2 } = data

  const bgColor = bg || containerVariants[theme || 'light'].bg
  const txtColor = containerVariants[theme || 'light'].text
  const columnView = alignment === 'left' ? 'column-reverse' : 'column'
  const rowView = alignment === 'left' ? 'row-reverse' : 'row'
  const gridTemplateColumns = ['1fr', '1fr', gridColumns[columns]]
  const itemsAlignment =
    columns === 1
      ? ['center', 'center', 'left']
      : ['center', 'center', 'center', 'flex-start']

  return (
    <Box as="section" bg={bgColor} flex="1" justifyContent="center" py={[4, 5]}>
      <Box
        flex="1"
        flexDirection={['column', columnView, rowView]}
        justifyContent="center"
        maxWidth="containers.lg"
      >
        {/* VALUE PROPS */}

        <Box
          alignItems={itemsAlignment}
          color={txtColor}
          flexDirection="column"
        >
          <Title alignSelf="center" mb={4} title={title} />
          <Grid
            gridGap={1}
            gridTemplateColumns={gridTemplateColumns}
            gridTemplatesRows="auto"
            justifyItems={columns === 1 ? 'start' : 'stretch'}
          >
            {items &&
              items.map((value, idx) => {
                return (
                  <Box key={idx} as={Text} mt={1}>
                    {value.icon && (
                      <Icon
                        aria-hidden="true"
                        height="1.8rem"
                        ml={3}
                        mr={3}
                        name={value.icon}
                        pt={1}
                        title={value.title?.main.text}
                        width="1.8rem"
                      />
                    )}

                    {value.emoji && (
                      <Box
                        aria-hidden="true"
                        as="span"
                        height="1.8rem"
                        ml={3}
                        mr={3}
                        role="img"
                        width="1.8rem"
                      >
                        {String.fromCharCode(value.emoji)}
                      </Box>
                    )}

                    <Box flexDirection="column" maxWidth="60rem">
                      {value.title?.main && (
                        <Text pt={1} variant="kilo">
                          {value.title.main.text}
                        </Text>
                      )}
                    </Box>
                  </Box>
                )
              })}
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

GridList.propTypes = propTypes

export { GridList }
