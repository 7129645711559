import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link as GatsbyLink, navigate } from 'gatsby'
import { Stack, Box, Link, Button } from '@rebeldotcom/components'
import {
  DomainSearchBar,
  InfoCollector,
  InfoCollectorEmbed,
} from '@rebeldotcom/ui'

import { Title } from '../../title'
import { Content } from '../../content'
import Img from '../../image'
import { DomainProductSearch } from '../../features/product-configurators'
import {
  getImageResponse,
  getImageProps,
  textAlignment,
  verticalAlignment,
  containerVariants,
  handleCTAClick,
} from '../../../utilities/panels'
import SectionBG from '../../section-bg'

const propTypes = {
  data: PropTypes.shape({
    alignment: PropTypes.oneOf(['left', 'center', 'right']),
    bg: PropTypes.string,
    btnAriaLabel: PropTypes.string,
    btnColor: PropTypes.string,
    btnText: PropTypes.string,
    color: PropTypes.string,
    content: PropTypes.string,
    ctas: PropTypes.array,
    fineprint: PropTypes.string,
    id: PropTypes.string.isRequired,
    image: PropTypes.shape({
      altText: PropTypes.string,
      background: PropTypes.bool,
      breakpoints: PropTypes.arrayOf(PropTypes.number),
      dim: PropTypes.number,
      path: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.shape({}),
        }),
      }),
      size: PropTypes.oneOf(['full', 'large', 'medium', 'small', 'cover']),
      width: PropTypes.string,
    }),
    infoCollector: PropTypes.object,
    placeholder: PropTypes.string,
    promoTLDs: PropTypes.array,
    searchConfig: PropTypes.shape({
      addDomainProductPromoPackageToCart: PropTypes.object,
      allowBulkSearch: PropTypes.bool,
      domainSearchLimitedTo: PropTypes.array,
      domainSearchShowOnlySearch: PropTypes.bool,
      isTransfer: PropTypes.bool,
      showDomainProductSearchBar: PropTypes.bool,
      showSearchBar: PropTypes.bool,
    }),
    sectionProps: PropTypes.shape({}),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    textplacement: PropTypes.string,
    theme: PropTypes.oneOf(['light', 'dark']),
    title: PropTypes.shape({
      bottom: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      main: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      top: PropTypes.shape({
        bgColor: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }),
    tlds: PropTypes.arrayOf(PropTypes.string),
    vertAlignment: PropTypes.string,
  }).isRequired,
  headingSize: PropTypes.oneOf(['small', 'medium', 'large']),
  headingTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  variant: PropTypes.oneOf(['old', 'new']),
}

const defaultProps = {
  headingSize: 'medium',
  headingTag: 'h1',
  variant: 'old',
}

const Hero = ({ data, headingTag, variant }) => {
  const {
    bg,
    fineprint,
    image,
    content,
    ctas,
    alignment,
    title,
    theme,
    searchConfig,
    btnColor,
    btnText,
    btnAriaLabel,
    tlds,
    promoTLDs,
    id,
    textplacement,
    vertAlignment,
    placeholder,
    infoCollector,
    color,
  } = data

  const isRedesign = variant === 'new'

  const transferSearch = domain => {
    navigate(`/domains/domain-transfer-check?domain=${domain}`)
  }

  const renderSearchBar = searchConfigInfo => {
    if (searchConfigInfo.showSearchBar) {
      return (
        <Box alignSelf="stretch">
          <DomainSearchBar
            bordered={!isRedesign}
            btnAriaLabel={btnAriaLabel}
            btnColor={btnColor}
            btnText={btnText}
            bulkSearch={searchConfigInfo.allowBulkSearch}
            containerProps={{
              alignItems: ['center', textAlignment[alignment]],
              justifyContent: ['center', textAlignment[alignment]],
              maxWidth: 'containers.lg',
            }}
            id={`${id}-input`}
            isRedesign={isRedesign}
            onSearch={searchConfigInfo.isTransfer ? transferSearch : null}
            placeholder={placeholder}
            promoTLDs={promoTLDs}
            theme={theme}
            tlds={tlds}
          />
        </Box>
      )
    }
    if (searchConfigInfo.showDomainProductSearchBar) {
      return (
        <Box alignSelf="stretch" mt="2rem">
          <DomainProductSearch
            addDomainProductPromoPackageToCart={
              searchConfig.addDomainProductPromoPackageToCart
            }
            bordered={!isRedesign}
            btnAriaLabel={btnAriaLabel}
            btnColor={btnColor}
            btnText={btnText}
            containerProps={{
              alignItems: ['center', textAlignment[alignment]],
              justifyContent: ['center', textAlignment[alignment]],
              maxWidth: 'containers.lg',
            }}
            id={`${id}-input`}
            onSearch={searchConfigInfo.isTransfer ? transferSearch : null}
            placeholder={placeholder}
            searchLimitedTo={searchConfigInfo.domainSearchLimitedTo}
            showOnlySearch={searchConfigInfo.domainSearchShowOnlySearch}
            theme={theme}
          />
        </Box>
      )
    }
    return null
  }

  return (
    <SectionBG
      alignItems={verticalAlignment[vertAlignment]}
      bg={bg || containerVariants[theme].bg}
      color={containerVariants[theme].text}
      containerProps={{
        alignItems: ['center', 'center', textAlignment[alignment]],
        maxWidth: alignment === 'center' ? 'containers.lg' : 'containers.xxl',
      }}
      id={id}
      image={!isRedesign ? image : null}
      justifyContent={[
        'center',
        'center',
        textplacement === 'right' ? 'flex-end' : textAlignment[textplacement],
      ]}
      overflow={infoCollector?.embed ? 'visible' : 'hidden'}
      position="relative"
      pt={5}
      px={[3, 5]}
      py={isRedesign ? [5, 4, 1] : [4, 5]}
      theme={theme}
    >
      <Box
        alignItems="flex-end"
        alignSelf="center"
        flex="1"
        flexDirection={['column', 'column', 'row']}
        justifyContent={
          isRedesign
            ? 'space-between'
            : ['center', 'center', textAlignment[alignment]]
        }
        maxWidth={isRedesign ? 'containers.lg' : null}
        width="100%"
      >
        <Stack
          alignItems={[
            'center',
            'center',
            textAlignment[alignment] || 'flex-start',
          ]}
          alignSelf="center"
          width={['100%', '100%', alignment === 'center' ? '100%' : '100%']}
        >
          <Title
            alignment={alignment}
            as={headingTag || 'h1'}
            isRedesign={isRedesign}
            lineHeight={['1.3']}
            my={4}
            theme={theme}
            title={title}
            variant={
              isRedesign
                ? ['teraBold', 'petaBold', 'exaBold', 'zettaBold']
                : ['gigaBold', 'petaBold', 'exaBold']
            }
          />

          {content && (
            <Content
              color={color}
              lineHeight="title"
              mb={4}
              textAlign={['center', alignment]}
              variant={['milli', 'pound', 'giga']}
            >
              {content}
            </Content>
          )}
          {searchConfig && renderSearchBar(searchConfig)}
          {(ctas || infoCollector) && (
            <Box
              alignItems="center"
              flexWrap="wrap"
              justifyContent={['center', textAlignment[alignment]]}
              mb={5}
            >
              {ctas &&
                ctas.map(cta => {
                  if (cta.type === 'addToCart') {
                    return (
                      <Button
                        key={cta.id}
                        ariaLabel={cta.title || cta.text}
                        color={cta.color}
                        id={cta.id}
                        onClick={() => handleCTAClick(cta)}
                        rel="nofollow"
                        variant={cta.variant}
                      >
                        {cta.text}
                      </Button>
                    )
                  }

                  const linkLocation = cta.isCMS
                    ? { to: cta.href }
                    : { href: cta.href }

                  return (
                    <Link
                      key={cta.id}
                      ariaLabel={cta.title}
                      as={cta.isCMS ? GatsbyLink : Link}
                      // {...rest}
                      color={cta.color}
                      id={cta.id}
                      mr={2}
                      size={cta.size}
                      variant={cta.variant}
                      {...linkLocation}
                    >
                      {cta.text}
                    </Link>
                  )
                })}

              {infoCollector && !infoCollector.embed && (
                <Box>
                  <InfoCollector
                    btnProps={{
                      color: infoCollector.btnProps?.color || 'blueDark',
                      variant: infoCollector.btnProps?.variant || 'solid',
                      size: 'wide',
                    }}
                    buttonText={infoCollector.buttonText}
                    errorMessage={infoCollector.errorMessage}
                    fields={infoCollector.fields}
                    source={infoCollector.source}
                    successMessage={infoCollector.successMessage}
                    title={infoCollector.title}
                  />
                </Box>
              )}
            </Box>
          )}
          {fineprint && (
            <Box
              alignItems={['center', textAlignment[alignment]]}
              justifyContent={['center', textAlignment[alignment]]}
              maxWidth="containers.md"
              mt="2rem"
              width="100%"
            >
              <Content
                color={color || containerVariants[theme || 'light'].text}
                fontSize={1}
                lineHeight="title"
                maxWidth="50rem"
                textAlign={['center', alignment]}
              >
                {fineprint}
              </Content>
            </Box>
          )}
        </Stack>
        {infoCollector && infoCollector.embed ? (
          <Box
            justifyContent="center"
            mb={[3]}
            ml={[0, 0, 4]}
            width={['100%', '100%', '50%']}
          >
            <Box
              bg={infoCollector.bg}
              bottom={[0, 0, '-4rem']}
              p={3}
              pb={4}
              position="relative"
            >
              <InfoCollectorEmbed
                btnProps={{
                  color: infoCollector.btnProps?.color || 'blueDark',
                  variant: infoCollector.btnProps?.variant || 'solid',
                  size: 'wide',
                }}
                buttonText={infoCollector.buttonText}
                errorMessage={infoCollector.errorMessage}
                fields={infoCollector.fields}
                source={infoCollector.source}
                successMessage={infoCollector.successMessage}
                title={infoCollector.title}
              />
            </Box>
          </Box>
        ) : null}
        {isRedesign && image ? (
          <Box
            alignSelf="center"
            ml={4}
            my={4}
            width="50%"
            {...getImageResponse(image)}
            position="relative"
          >
            <Img
              alt={image.altText}
              display={['block']}
              height="45rem"
              objectFit="contain"
              top="0px"
              {...getImageProps(image)}
              width={image.width || '100%'}
            />
          </Box>
        ) : null}
      </Box>
    </SectionBG>
  )
}

Hero.propTypes = propTypes
Hero.defaultProps = defaultProps
Hero.displayName = 'Hero'

export { Hero }

export const heroFragment = graphql`
  fragment HeroPanel on Panel {
    id
    size
    textplacement
    vertAlignment
    title {
      ...TitleProps
    }
    searchConfig {
      showSearchBar
      showDomainProductSearchBar
      domainSearchLimitedTo
      domainSearchShowOnlySearch
      addDomainProductPromoPackageToCart {
        PromoEndpoint
        HostingPackageName
        PromoCode
      }
      allowBulkSearch
      isTransfer
    }
    content
    color
    bg
    borderedSearch
    fineprint
    btnColor
    btnText
    btnAriaLabel
    placeholder
    theme
    ctas {
      ...CTAProps
    }
    image {
      ...HeroImageProps
    }
    dynamicImage {
      ...DynamicImageProps
    }
    tlds
  }
`
