import React from 'react'
import PropTypes from 'prop-types'
import { Stack, Box } from '@rebeldotcom/components'
import Img from './image'
import {
  containerVariants,
  getImageResponse,
  getImageProps,
} from '../utilities/panels'

const propTypes = {
  bg: PropTypes.string,
  children: PropTypes.node.isRequired,
  containerProps: PropTypes.shape({}),
  image: PropTypes.shape({
    altText: PropTypes.string,
    background: PropTypes.bool,
    breakpoints: PropTypes.arrayOf(PropTypes.number),
    dim: PropTypes.number,
    height: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number),
    ]),
    path: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({}),
      }),
    }),
    position: PropTypes.shape({
      x: PropTypes.oneOf(['left', 'center', 'right']),
      y: PropTypes.oneOf(['top', 'center', 'bottom']),
    }),
    size: PropTypes.oneOf(['full', 'large', 'medium', 'small', 'cover']),
    width: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number),
    ]),
  }),
  px: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  theme: PropTypes.oneOf(['light', 'dark']),
}

const defaultProps = {
  bg: null,
  containerProps: {},
  image: null,
  px: 0,
  theme: 'dark',
}

const SectionBG = ({
  children,
  containerProps,
  image,
  px,
  theme,
  bg,
  ...rest
}) => {
  const bgColor = bg || containerVariants[theme || 'light'].bg

  const getImageYPosition = () => {
    if (!image.position || !image.position.y) {
      return {}
    }

    if (image.size === 'cover') return { top: 0 }

    const { y } = image.position

    switch (y) {
      case 'top':
        return {
          top: 0,
          bottom: 'unset',
        }
      case 'center':
        return {
          top: '15%',
        }
      case 'bottom': {
        return {
          bottom: 0,
          top: 'unset',
        }
      }
      default:
        return {}
    }
  }

  const getImageXPosition = () => {
    if (!image.position || !image.position.x) {
      return {}
    }

    const { x } = image.position

    switch (x) {
      case 'left':
        return {
          left: 0,
          right: 'unset',
        }
      case 'right': {
        return {
          right: 0,
          left: 'unset',
        }
      }
      case 'center': {
        return {
          left: '50%',
          transform: 'translateX(-50%)',
        }
      }
      default:
        return {}
    }
  }

  const getImagePosition = () => {
    return {
      ...getImageYPosition(),
      ...getImageXPosition(),
    }
  }

  const getImageWidth = () => {
    if (image.width) return image.width

    switch (image.size) {
      case 'small':
        return '50%'
      case 'medium':
        return '70%'
      case 'large':
        return '85%'
      default:
        return '100%'
    }
  }

  const getImageHeight = () => {
    if (image.height) return image.height

    switch (image.size) {
      case 'cover':
        return '100%'
      default:
        return 'unset'
    }
  }

  return (
    <Box
      as="section"
      bg={bgColor}
      color={containerVariants[theme || 'light'].text}
      justifyContent="center"
      overflow="hidden"
      position="relative"
      width={1}
      {...rest}
      alignItems="stretch"
    >
      {image && (
        <>
          <Img
            alt={image.altText}
            height="100%"
            imgStyle={{
              height: getImageHeight(),
              width: getImageWidth(),
              ...getImagePosition(),
            }}
            left="0px"
            maxWidth="100%"
            style={{
              position: 'absolute',
            }}
            top="0px"
            width="100%"
            {...getImageProps(image)}
            {...getImageResponse(image)}
          />
          {image.dim > 0 && (
            <Box
              bg={theme === 'light' ? 'white' : 'black'}
              height="100%"
              left="0"
              opacity={image.dim * 0.2}
              position="absolute"
              top="0"
              width="100%"
            />
          )}
        </>
      )}
      <Stack
        alignItems="center"
        flexDirection="column"
        maxWidth="containers.lg"
        px={px}
        width={1}
        zIndex="9"
        {...containerProps}
      >
        {children}
      </Stack>
    </Box>
  )
}

SectionBG.propTypes = propTypes
SectionBG.defaultProps = defaultProps

export default SectionBG
