import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link as GatsbyLink } from 'gatsby'
import {
  Link,
  Text,
  Section,
  Grid,
  Stack,
  Button,
} from '@rebeldotcom/components'
import { Title } from '../../title'
import { Content } from '../../content'
import Img from '../../image'
import {
  containerVariants,
  getImageProps,
  handleCTAClick,
} from '../../../utilities/panels'

const propTypes = {
  data: PropTypes.shape({
    bg: PropTypes.string,
    content: PropTypes.string,
    id: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        ctas: PropTypes.arrayOf(
          PropTypes.shape({
            color: PropTypes.string,
            href: PropTypes.string,
            id: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['link', 'addToCart']),
            variant: PropTypes.string,
          })
        ),
        icon: PropTypes.string,
        price: PropTypes.object,
        title: PropTypes.shape({
          bottom: PropTypes.shape({
            text: PropTypes.string,
            textColor: PropTypes.string,
          }),
          main: PropTypes.shape({
            text: PropTypes.string,
            textColor: PropTypes.string,
          }),
          top: PropTypes.shape({
            bgColor: PropTypes.string,
            text: PropTypes.string,
            textColor: PropTypes.string,
          }),
        }),
      })
    ).isRequired,
    maxColumns: PropTypes.number,
    theme: PropTypes.oneOf(['light', 'dark']),
    title: PropTypes.shape({
      bottom: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      main: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      top: PropTypes.shape({
        bgColor: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }),
  }).isRequired,
}

const Twin = ({ data }) => {
  const { id, title, content, items, theme, bg } = data

  return (
    <Section
      bg={bg || containerVariants[theme || 'light'].bg}
      color={containerVariants[theme || 'light'].text}
      containerProps={{
        maxWidth: 'containers.lg',
      }}
      id={id}
      px={[4]}
      py={[3, 3, 4]}
    >
      {title || content ? (
        <Stack maxWidth="containers.sm" mb={4}>
          {title && (
            <Title
              theme={theme}
              title={title}
              variant={['teraBold', 'petaBold']}
            />
          )}

          {content && (
            <Content
              maxWidth="containers.md"
              mt={3}
              textAlign="center"
              variant="mega"
            >
              {content}
            </Content>
          )}
        </Stack>
      ) : null}

      <Grid
        gridGap={[3, 5]}
        gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 1fr']}
        justifyContent="center"
        mt={3}
      >
        {items.map((item, idx) => {
          return (
            <Grid
              key={idx}
              bg={item.bg || 'white'}
              gridTemplateColumns={['1fr', '20rem 1fr']}
              maxWidth={['100%', '60rem']}
            >
              {item.image ? (
                <Img
                  alt={item.image.altText}
                  width={['100%', '50rem']}
                  {...getImageProps(item.image)}
                />
              ) : null}
              <Stack alignItems="flex-start" p={1} px={4} py={3}>
                {item.title ? (
                  <Stack mt={[3, 4]}>
                    {item.title.top ? (
                      <Content fontFamily="body" variant="milli">
                        {item.title.top.text}
                      </Content>
                    ) : null}
                    <Content fontFamily="title" variant="gigaBold">
                      {item.title.main.text}
                    </Content>
                  </Stack>
                ) : null}

                {item.content ? (
                  <Content lineHeight="body" mt={3} variant="pound">
                    {item.content}
                  </Content>
                ) : null}

                {item.ctas && item.ctas.length > 0 ? (
                  <Stack
                    alignItems="flex-start"
                    flex="1"
                    flexWrap="wrap"
                    justifyContent="flex-end"
                    maxWidth="50rem"
                    mt={4}
                  >
                    {item.ctas.map(cta => {
                      if (cta.type === 'addToCart') {
                        return (
                          <Button
                            key={cta.id}
                            ariaLabel={cta.title || cta.text}
                            color={cta.color}
                            id={cta.id}
                            onClick={() => handleCTAClick(cta)}
                            rel="nofollow"
                            variant={cta.variant}
                          >
                            {cta.text}
                          </Button>
                        )
                      }

                      const linkLocation = cta.isCMS
                        ? { to: cta.href }
                        : { href: cta.href }

                      return (
                        <Link
                          key={cta.id}
                          ariaLabel={cta.title}
                          as={cta.isCMS ? GatsbyLink : Link}
                          color={cta.color}
                          id={cta.id}
                          mb={2}
                          size={cta.size}
                          {...linkLocation}
                          // {...rest}
                          variant={cta.variant}
                        >
                          <Text variant={['milli', 'pound']}>{cta.text}</Text>
                        </Link>
                      )
                    })}
                  </Stack>
                ) : null}
              </Stack>
            </Grid>
          )
        })}
      </Grid>
    </Section>
  )
}

Twin.propTypes = propTypes

export { Twin }

export const twinFragment = graphql`
  fragment TwinPanel on Panel {
    title {
      ...TitleProps
    }
    theme
    content
    maxColumns
    items {
      ...ItemProps
    }
  }
`
