import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import css from '@styled-system/css'
import { Box, Icon, Text, Section, Grid, Stack } from '@rebeldotcom/components'
import { useSessionService, Price } from '@rebeldotcom/ui'
import Img from '../../image'
import { Title } from '../../title'
import { containerVariants, getImageProps } from '../../../utilities/panels'
import { Content } from '../../content'

const propTypes = {
  data: PropTypes.shape({
    bg: PropTypes.string,
    color: PropTypes.string,
    content: PropTypes.string,
    highlight: PropTypes.shape({
      bg: PropTypes.string,
      content: PropTypes.string,
      href: PropTypes.string.isRequired,
      icon: PropTypes.string,
      id: PropTypes.string.isRequired,
      image: PropTypes.shape({
        altText: PropTypes.string,
        path: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({}),
          }),
        }),
        width: PropTypes.string,
      }),
      price: PropTypes.object,
      title: PropTypes.object,
    }),
    id: PropTypes.string.isRequired,
    maxColumns: PropTypes.number,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        ctas: PropTypes.arrayOf(
          PropTypes.shape({
            color: PropTypes.string,
            href: PropTypes.string,
            id: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            title: PropTypes.object.isRequired,
            type: PropTypes.oneOf(['link', 'addToCart']),
            variant: PropTypes.string,
          })
        ),
        icon: PropTypes.string,
        price: PropTypes.object,
        title: PropTypes.object,
      })
    ).isRequired,
    theme: PropTypes.oneOf(['light', 'dark']),
    title: PropTypes.shape({
      bottom: PropTypes.string,
      main: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      top: PropTypes.shape({
        bgColor: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }),
  }).isRequired,
}

const Product = styled.a`
  cursor: pointer;
  align-items: flex-start;

  ${({ theme: { colors, lineHeights, space }, product: { bg: prodBg } }) => `
    transition: all .3s;
    background: ${colors[prodBg] || ''};
    color: ${colors.blackLight};
    border: 1px solid ${colors.greyLight};
    line-height: ${lineHeights.body};
    padding: ${space[4]};

    &:hover {
      color: ${colors.whiteDark};
      background: ${colors.blackLight};
      border: 1px solid ${colors.blackLight};
      text-decoration: underline;
    }  
    
    &:hover div { 
      color: ${colors.whiteDark};
    }
  `}
`

const Highlight = styled(Product)(
  css({
    gridColumn: ['1', '1 / span 2', '-1'],
    gridRow: ['', '', '1 / span 3', '1 / span 2'],
  })
)

const SmallProductGrid = ({ data }) => {
  const {
    data: {
      currentCurrency: { code: currentCode },
    },
  } = useSessionService()
  const {
    title,
    content,
    products,
    theme,
    maxColumns,
    id,
    bg,
    highlight,
    color,
  } = data
  const count = products.length
  const mobileGrid = '1fr'
  const smallGrid = count % 2 === 0 ? 'repeat(2, 1fr)' : '1fr'
  const largeGrid = `repeat(${maxColumns || 4}, 1fr)`

  const renderPrice = price => {
    if (!price) return null

    let displayPrice = Price.getDisplayPrice(price, currentCode)

    if (price.dynamicPrice?.prices) {
      const combinedPrice = { ...price, ...price.dynamicPrice?.prices }
      displayPrice = Price.getDisplayPrice(
        combinedPrice,
        currentCode.toUpperCase()
      )
    }
    return (
      <Stack
        alignItems="flex-start"
        alignSelf="stretch"
        flex="1"
        justifyContent="flex-end"
      >
        <Box alignItems="flex-end" mt={1}>
          {price.prefix ? (
            <Text as="span" color={color} mr={1} variant="milli">
              {price.prefix}
            </Text>
          ) : null}
          <Text
            as="span"
            color={color}
            fontFamily="title"
            lineHeight="1.6"
            mr={1}
            variant="mega"
          >
            {displayPrice}
          </Text>
          {price.suffix ? (
            <Text as="span" color={color} variant="milli">
              {price.suffix}
            </Text>
          ) : null}
        </Box>
      </Stack>
    )
  }

  return (
    <Section
      bg={bg || containerVariants[theme || 'light'].bg}
      color={containerVariants[theme || 'light'].text}
      containerProps={{
        maxWidth: 'containers.lg',
      }}
      id={id}
      px={[3, 4]}
      py={[4, 5]}
    >
      {title ? (
        <Title
          mb={4}
          theme={theme}
          title={title}
          variant={['teraBold', 'petaBold']}
        />
      ) : null}

      {content ? (
        <Content
          maxWidth="containers.sm"
          mb={[5]}
          textAlign="center"
          variant="mega"
        >
          {content}
        </Content>
      ) : null}

      <Grid
        alignSelf="stretch"
        gridGap={2}
        gridTemplateColumns={[mobileGrid, smallGrid, smallGrid, largeGrid]}
        justifyContent="center"
        maxWidth="120rem"
      >
        {products.map((product, idx) => {
          return (
            <Product key={idx} href={product.href} product={product}>
              <Stack>
                {product.icon ? (
                  <Icon
                    height="4rem"
                    mb={4}
                    name={product.icon}
                    title={product.title.main.text}
                    width="4rem"
                  />
                ) : null}

                {product.image ? (
                  <Img
                    alt={product.image.altText}
                    height="5rem"
                    width={product.image.width || '5rem'}
                    {...getImageProps(product.image)}
                  />
                ) : null}

                {product.title && product.title.main.text ? (
                  <Text fontFamily="title" variant="gigaBold">
                    {product.title.main.text}
                  </Text>
                ) : null}

                {product.content ? (
                  <Content mt={3} variant="pound">
                    {product.content}
                  </Content>
                ) : null}

                {renderPrice(product.price)}
              </Stack>
            </Product>
          )
        })}
        {
          // Highlight was commented out when Weebly was removed and we no longer had an odd number of items on the home page. If Weebly is replaced, this will need to come back.
          /* <Highlight href={highlight.href} product={highlight}>
          <Stack>
            {highlight.icon ? (
              <Icon
                height="4rem"
                mb={4}
                name={highlight.icon}
                title={highlight.title}
                width="4rem"
              />
            ) : null}

            {highlight.image ? (
              <Img
                alt={highlight.image.altText}
                height="5rem"
                width={highlight.image.width || '5rem'}
                {...getImageProps(highlight.image)}
              />
            ) : null}

            {highlight.title && highlight.title.main.text ? (
              <Text color={color} fontFamily="title" variant="gigaBold">
                {highlight.title.main.text}
              </Text>
            ) : null}

            {highlight.content ? (
              <Content color={color} mt={3} variant="pound">
                {highlight.content}
              </Content>
            ) : null}

            {highlight.price ? (
              <Stack
                alignItems="flex-start"
                alignSelf="stretch"
                flex="1"
                justifyContent="flex-end"
              >
                {highlight.price ? (
                  <Box alignItems="flex-end" mt={1}>
                    {highlight.price.prefix ? (
                      <Text as="span" mr={1} variant="milli">
                        {highlight.price.prefix}
                      </Text>
                    ) : null}
                    <Text
                      as="span"
                      fontFamily="title"
                      lineHeight="1.6"
                      mr={1}
                      variant="mega"
                    >{`${Price.getDisplayPrice(
                      highlight.price,
                      currentCode
                    )}`}</Text>
                    {highlight.price.suffix ? (
                      <Text as="span" variant="milli">
                        {highlight.price.suffix}
                      </Text>
                    ) : null}
                  </Box>
                ) : null}
              </Stack>
            ) : null}
          </Stack>
        </Highlight> */
        }
      </Grid>
    </Section>
  )
}

SmallProductGrid.propTypes = propTypes
export { SmallProductGrid }
