import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  Box,
  Text,
  Section,
  Stack,
  Grid,
  Heading,
  Icon,
  redesignTheme,
  Link,
} from '@rebeldotcom/components'
import { isInternic } from '@rebeldotcom/ui'
import { containerVariants, getImageProps } from '../../../utilities/panels'
import Img from '../../image'

const { colors } = redesignTheme

const propTypes = {
  data: PropTypes.shape({
    bg: PropTypes.string,
    color: PropTypes.string,
    contractors: PropTypes.arrayOf(
      PropTypes.shape({
        availableServices: PropTypes.array.isRequired,
        color: PropTypes.string,
        contact: PropTypes.shape({
          email: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
          phone: PropTypes.string.isRequired,
          website: PropTypes.string.isRequired,
        }),
        cost: PropTypes.number,
        description: PropTypes.string.isRequired,
        image: PropTypes.string,
        location: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    id: PropTypes.string.isRequired,
    theme: PropTypes.oneOf(['light', 'dark']),
  }).isRequired,
}

const RWDContractor = ({ data }) => {
  const { contractors, id, bg, theme } = data
  const defaultButtonColour = isInternic ? 'blue' : 'deepKoamaru'

  return (
    <Section
      bg={bg || containerVariants[theme || 'light'].bg}
      color={containerVariants[theme || 'light'].text}
      containerProps={{
        maxWidth: 'containers.md',
      }}
      id={id}
      py={[4, 5]}
    >
      <Box alignItems="center" flexDirection="column" justifyContent="center">
        {contractors
          ? contractors.map((contractor, index) => (
              <Box
                key={`contractor-${index}`}
                border={`1px solid ${colors.grey}`}
                maxWidth="containers.md"
                mb={3}
                minWidth="containers.sm"
                p={4}
              >
                <Grid gridGap={[2, 4]} gridTemplateColumns={['1fr 2fr 1fr']}>
                  <Img
                    alt={`logo-${contractor.name}`}
                    display={['block']}
                    height="18rem"
                    objectFit="contain"
                    title={`logo-${contractor.name}`}
                    top="0px"
                    {...getImageProps(contractor.image)}
                  />
                  <Stack>
                    <Heading variant="teraBold">{contractor.name}</Heading>
                    <Text variant="kiloBold">{contractor.location}</Text>
                    <Text mt={2}>{contractor.description}</Text>
                    {contractor.cost ? (
                      <Box mt={2} width="auto">
                        <Heading
                          bg="blueLight"
                          color="deepKoamaru"
                          pl={2}
                          pr={3}
                          variant="megaBold"
                        >
                          Starting at ${contractor.cost}
                        </Heading>
                      </Box>
                    ) : null}
                    <Link
                      ariaLabel={`contact-${contractor.name}`}
                      color={
                        contractor.color
                          ? contractor.color
                          : defaultButtonColour
                      }
                      href={contractor.contact.link}
                      mt={4}
                      newTab
                      variant="solid"
                    >
                      Contact {contractor.name}
                    </Link>
                  </Stack>
                  <Box bg="#ffeccd" p={3}>
                    <Stack>
                      <Stack>
                        <Text variant="kiloBold">Available Services:</Text>
                        {contractor.availableServices.map((service, idx) => {
                          return (
                            <Box key={`available-services-${idx}`}>
                              <Icon
                                height="20px"
                                name="checkmark"
                                pr={1}
                                title={`bullet-point-${idx}-${contractor.name}`}
                              />
                              <Text variant="kilo">{service}</Text>
                            </Box>
                          )
                        })}
                      </Stack>
                      <Stack mt={2}>
                        <Text variant="kiloBold">Contact:</Text>
                        <Link
                          ariaLabel={`email-${contractor.name}`}
                          href={`mailto:${contractor.contact.email}`}
                          textAlign="left"
                          variant="link"
                        >
                          <Text
                            color="black"
                            textTransform="none"
                            variant="kilo"
                          >
                            {contractor.contact.email}
                          </Text>
                        </Link>
                        <Text variant="kilo">{contractor.contact.phone}</Text>
                        <Link
                          ariaLabel={`website-${contractor.name}`}
                          href={contractor.contact.website}
                          newTab
                          textAlign="left"
                          variant="link"
                        >
                          <Text
                            color="black"
                            textTransform="none"
                            variant="kilo"
                          >
                            {contractor.contact.website
                              .replace(/^http:\/\//, '')
                              .replace(/^https:\/\//, '')}
                          </Text>
                        </Link>
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
              </Box>
            ))
          : null}
      </Box>
    </Section>
  )
}

RWDContractor.propTypes = propTypes

export { RWDContractor }

export const rwdContractorFragment = graphql`
  fragment RWDContractorPanel on Panel {
    theme
    color
    contractors {
      name
      location
      description
      cost
      color
      image {
        ...ImageProps
      }
      contact {
        email
        phone
        website
        link
      }
      availableServices
    }
  }
`
