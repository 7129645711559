import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Video = styled.div``

const Iframe = styled.iframe``

const YoutubeEmbed = ({ embedId }) => (
  <Video>
    <Iframe
      width="550"
      height="400"
      src={`https://www.youtube.com/embed/${embedId}?rel=0`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </Video>
)

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
}

export default YoutubeEmbed
