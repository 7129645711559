import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box, Text, Section } from '@rebeldotcom/components'
import Image from '../../image'
import { Title } from '../../title'
import { containerVariants } from '../../../utilities/panels'

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    team: PropTypes.arrayOf(
      PropTypes.shape({
        bio: PropTypes.string,
        image: PropTypes.shape({
          altText: PropTypes.string,
          path: PropTypes.shape({
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.shape({}),
            }),
          }),
        }),
        name: PropTypes.string,
      })
    ),
    theme: PropTypes.oneOf(['light', 'dark']),
    title: PropTypes.shape({
      bottom: PropTypes.string,
      main: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      top: PropTypes.shape({
        bgColor: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }),
  }).isRequired,
}

const OurTeam = ({ data }) => {
  const { title, team, theme, id } = data

  return (
    <Section
      bg={containerVariants[theme].bg}
      color={containerVariants[theme].text}
      id={id}
      px={[2, 3, 5]}
      py={5}
    >
      <Title theme={theme} title={title} />

      {team &&
        team.map(member => {
          return (
            <Box
              key={member.name}
              alignItems="center"
              bg={containerVariants[theme === 'dark' ? 'light' : 'dark'].bg}
              color={
                containerVariants[theme === 'dark' ? 'light' : 'dark'].text
              }
              flexDirection={['column', 'column', 'row']}
              maxWidth="90rem"
              mt={4}
              p={4}
              width={[1, 1, 0.9]}
            >
              <Image
                alt={member.image.altText}
                fluid={member.image.path.childImageSharp.fluid}
                height="15rem"
                mb={[4, 4, 0]}
                width="15rem"
              />
              <Box flexDirection="column" ml={4} width={3 / 4}>
                <Text fontSize={3} fontWeight="semi" textStyle="caps">
                  {member.name}
                </Text>
                <Text fontSize={2} lineHeight="body" mt={2}>
                  {member.bio.split('\n').map((item, idx) => {
                    return (
                      <Text key={idx} mb={3}>
                        {item}
                        <br />
                      </Text>
                    )
                  })}
                </Text>
              </Box>
            </Box>
          )
        })}
    </Section>
  )
}

OurTeam.propTypes = propTypes

export { OurTeam }

export const ourTeamFragment = graphql`
  fragment OurTeamPanel on Panel {
    title {
      ...TitleProps
    }
    team {
      bio
      name
      image {
        ...ImageProps
      }
    }
  }
`
