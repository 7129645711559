import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

const SiteQuery = () => {
  const { site } = useStaticQuery(
    graphql`
      query somequery {
        site {
          siteMetadata {
            title
            shortName
            address
            phone
            host
          }
        }
      }
    `
  )

  let blueSnapTitle = ''
  if (site.siteMetadata.title.valueOf() === 'Rebel.com') {
    blueSnapTitle = 'Rebel Ltd.'
  } else if (site.siteMetadata.title.valueOf() === 'Rebel.ca') {
    blueSnapTitle = 'Rebel.ca Corp.'
  } else if (site.siteMetadata.title.valueOf() === 'Internic.ca') {
    blueSnapTitle = 'Internic.ca Inc.'
  }
  site.siteMetadata.BlueSnapTitle = blueSnapTitle

  let blueSnapSubsidiaryRef
  if (site.siteMetadata.title.valueOf() === 'Rebel.com') {
    blueSnapSubsidiaryRef =
      ' - including without limitation to or from Rebel.ca Corp'
  } else if (site.siteMetadata.title.valueOf() === 'Rebel.ca') {
    blueSnapSubsidiaryRef =
      ' - including without limitation to or from Rebel Ltd'
  } else {
    blueSnapSubsidiaryRef = ' '
  }
  site.siteMetadata.BlueSnapSubsidiaryRef = blueSnapSubsidiaryRef

  return site.siteMetadata
}

export const GetSiteMetaData = () => {
  const site = SiteQuery()
  return site
}

export const Metadata = props => {
  const { name } = props
  const site = SiteQuery()

  const link = ({ children, path }) => (
    <a href={`https://www.${site.host}${path || ''}`}>
      {children || `${site.host}${path || ''}`}
    </a>
  )
  link.propTypes = {
    children: PropTypes.node.isRequired,
    path: PropTypes.string,
  }
  link.defaultProps = {
    path: '',
  }

  const mailLink = ({ addr }) => (
    <a href={`mailto:${addr}@${site.host}`}>{`${addr}@${site.host}`}</a>
  )
  mailLink.propTypes = {
    addr: PropTypes.string.isRequired,
  }

  const resellerConditional = ({ children, match, value }) =>
    site[match] === value ? children : ''
  resellerConditional.propTypes = {
    children: PropTypes.node.isRequired,
    match: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }

  const tpl = { mailLink, link, resellerConditional }

  if (tpl[name]) {
    return tpl[name](props)
  }

  return site[name] ? (
    site[name]
  ) : (
    <span style={{ backgroundColor: '#ff0000' }}>{name}</span>
  )
}

Metadata.propTypes = {
  name: PropTypes.string.isRequired,
}
