import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Grid,
  Text,
  Section,
  Box,
  Link,
  Stack,
  theme as systemTheme,
} from '@rebeldotcom/components'
import { Price } from '@rebeldotcom/ui'
import { Content } from '../../content'
import { Title } from '../../title'

const { colors } = systemTheme

const propTypes = {
  data: PropTypes.shape({
    featureList: PropTypes.object.isRequired,
    fineprint: PropTypes.string,
    id: PropTypes.string.isRequired,
    packages: PropTypes.array.isRequired,
    title: PropTypes.shape({
      bottom: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      main: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      top: PropTypes.shape({
        bgColor: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }).isRequired,
    variant: PropTypes.oneOf(['light', 'dark']),
  }).isRequired,
}

const FeatureComparison = ({ data }) => {
  const { packages, title, fineprint, featureList, variant, id } = data
  const currentTab = useRef()
  const tabGrid = useRef()
  const tabPanel = useRef()

  const [selectedPackage, setSelectedPackage] = useState(packages[0])

  const templateRows = [
    `1fr 1fr repeat(${Object.keys(featureList).length + 1}, 8rem)`,
    `1fr 1fr repeat(${Object.keys(featureList).length + 1}, 8rem)`,
    `1fr 1fr repeat(${Object.keys(featureList).length + 1}, 8rem)`,
    `1fr 1fr repeat(${Object.keys(featureList).length + 1}, 6rem)`,
  ]
  const templateColumns = `2fr repeat(${packages.length}, 1fr)`

  // Create row for visual purposes
  const bottomRow = [...Array(packages.length + 1).keys()]

  const getTabIndex = pk => {
    return pk === selectedPackage ? null : { tabIndex: '-1' }
  }

  const tabTrap = e => {
    switch (e.key) {
      case 'ArrowDown':
        tabPanel.current.focus()
        e.preventDefault()
        break
      case 'ArrowUp':
        currentTab.current.focus()
        e.preventDefault()
        break
      case 'ArrowLeft':
        if (selectedPackage === packages[0]) {
          setSelectedPackage(packages[packages.length - 1])
          currentTab.current.focus()
        } else {
          setSelectedPackage(packages[packages.indexOf(selectedPackage) - 1])
          currentTab.current.focus()
        }
        break
      case 'ArrowRight':
        if (selectedPackage === packages[packages.length - 1]) {
          setSelectedPackage(packages[0])
          currentTab.current.focus()
        } else {
          setSelectedPackage(packages[packages.indexOf(selectedPackage) + 1])
          currentTab.current.focus()
        }
        break
      default:
        break
    }
  }

  useEffect(() => {
    tabGrid.current.addEventListener('keydown', tabTrap)

    return () => {
      tabGrid.current.removeEventListener('keydown', tabTrap)
    }
  }, [tabTrap])

  return (
    <Section
      containerProps={{
        maxWidth: 'containers.lg',
      }}
      id={id}
      px={[2, 2, 5]}
      py={5}
    >
      <Title title={title} variant={variant} />

      <Stack
        ref={tabGrid}
        display={['flex', 'flex', 'none']}
        mt={4}
        width="100%"
      >
        <Grid
          as="ul"
          borderBottom="dark"
          borderLeft="dark"
          gridTemplateColumns="1fr 1fr"
          mb={3}
          role="tablist"
        >
          {packages.map(pk => (
            <Box
              key={pk.title.main}
              as="li"
              bg={pk === selectedPackage ? 'greys.8' : 'white'}
              borderRight="dark"
              borderTop="dark"
              role="presentation"
            >
              <Link
                aria-selected={pk === selectedPackage}
                ariaLabel={pk.title.main}
                height="100%"
                href={`#${pk.title.main}`}
                id={pk.title.main}
                onClick={() => setSelectedPackage(pk)}
                role="tab"
                width="100%"
                {...getTabIndex(pk)}
                ref={pk === selectedPackage ? currentTab : null}
              >
                <Box px={3} py={2}>
                  <Text
                    color={
                      pk === selectedPackage ? colors.black : colors.greys[3]
                    }
                    variant={pk === selectedPackage ? 'poundBold' : 'pound'}
                  >
                    {pk.title.main}
                  </Text>
                </Box>
              </Link>
            </Box>
          ))}
        </Grid>
        <Grid
          ref={tabPanel}
          aria-labelledby={selectedPackage.title.main}
          as="section"
          borderBottom="light"
          borderLeft="light"
          gridTemplateColumns="1fr 1fr"
          id={selectedPackage.title.main}
          role="tabpanel"
          tabIndex="0"
          width="100%"
        >
          {Object.keys(featureList).map((feature, index) => {
            return (
              <React.Fragment key={feature}>
                <Box
                  alignItems="center"
                  borderRight="light"
                  borderTop="light"
                  justifyContent="flex-end"
                  px={3}
                  py={2}
                >
                  <Text
                    fontWeight="semi"
                    textAlign="right"
                    textTransform="capitalize"
                    variant="milli"
                    width="100%"
                  >
                    {Object.values(featureList)[index]}
                  </Text>
                </Box>
                <Box
                  key={`${selectedPackage.title.main}-${feature}`}
                  alignItems="center"
                  borderRight="light"
                  borderTop="light"
                  justifyContent="center"
                >
                  <Text p={3} textAlign="center" variant="milli">
                    {selectedPackage.features[feature]}
                  </Text>
                </Box>
              </React.Fragment>
            )
          })}
        </Grid>
      </Stack>

      <Grid
        alignItems="center"
        border={1}
        display={['none', 'none', 'grid']}
        gridTemplateColumns={templateColumns}
        gridTemplateRows={templateRows}
        justifyItems="center"
        mt={4}
      >
        {/* TITLE ROW  */}

        <Box />
        {packages.map(pk => {
          return (
            <Stack
              key={`${pk.title.main}`}
              alignItems="center"
              borderLeft={1}
              height="100%"
              justifyContent="space-between"
              px={2}
              py={3}
              width="100%"
            >
              <Text flex="1" mb={3} textAlign="center">
                {pk.title.main}
              </Text>
              <Price price={pk.price} />
              {pk.ctas &&
                pk.ctas.map(cta => (
                  <Button
                    key={cta.id}
                    ariaLabel={cta.text}
                    color={cta.color}
                    id={cta.id}
                  >
                    {cta.text}
                  </Button>
                ))}
            </Stack>
          )
        })}

        {/* DESCRIPTION ROW */}

        <Box bg="greys.8" height="100%" width="100%" />
        {packages.map(pk => {
          return (
            <Box
              key={`${pk.title.main}-description`}
              alignItems="center"
              bg="greys.8"
              borderLeft={1}
              height="100%"
            >
              <Text fontSize={1} px={3} py={3} textAlign="center">
                {pk.description}
              </Text>
            </Box>
          )
        })}

        {/* FEATURELIST ROW */}

        {Object.keys(featureList).map((feature, index) => {
          return (
            <React.Fragment key={feature}>
              <Text
                fontWeight="semi"
                justifySelf="right"
                mr={[2, 3, 4]}
                textAlign="right"
                textTransform="capitalize"
              >
                {Object.values(featureList)[index]}
              </Text>
              {packages.map(pk => {
                return (
                  <Box
                    key={`${pk.title.main}-${feature}`}
                    alignItems="center"
                    borderLeft={1}
                    height="100%"
                    justifyContent="center"
                    width="100%"
                  >
                    <Text fontSize={1} p={2} textAlign="center">
                      {pk.features[feature]}
                    </Text>
                  </Box>
                )
              })}
            </React.Fragment>
          )
        })}

        {/* ROW JUST TO CREATE SPACE */}

        {bottomRow.map((cell, idx) => {
          return (
            <Box
              key={idx}
              borderLeft={idx > 0 && 1}
              height="100%"
              width="100%"
            />
          )
        })}
      </Grid>

      {fineprint && (
        <Content fontSize={0} maxWidth="container.md" mt={4}>
          {fineprint}
        </Content>
      )}
    </Section>
  )
}

FeatureComparison.propTypes = propTypes

export { FeatureComparison }
