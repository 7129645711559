import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Section } from '@rebeldotcom/components'
import { Accordion as AccordionComponent } from '../../compound/accordion'
import { Title } from '../../title'

const propTypes = {
  bg: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string.isRequired,
        title: PropTypes.shape({
          main: PropTypes.shape({
            text: PropTypes.string,
            textColor: PropTypes.string,
          }),
        }).isRequired,
      })
    ).isRequired,
    title: PropTypes.shape({
      bottom: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      main: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      top: PropTypes.shape({
        bgColor: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }),
  }).isRequired,
}

const defaultPropTypes = {
  bg: 'white',
}

const Accordion = ({ data, bg }) => {
  const { title, items, id } = data

  return (
    <Section id={id} px={3} py={5}>
      <Title title={title} />

      <AccordionComponent
        bg={bg}
        items={items}
        justifyContent="center"
        maxWidth="containers.md"
        mt={4}
      />
    </Section>
  )
}

Accordion.propTypes = propTypes
Accordion.defaultProps = defaultPropTypes

export { Accordion }

export const accordionFragment = graphql`
  fragment AccordionPanel on Panel {
    title {
      ...TitleProps
    }
    items {
      ...ItemProps
    }
  }
`
