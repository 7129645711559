import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Button, Box, Text, Stack } from '@rebeldotcom/components'
import { hasDOM } from '@rebeldotcom/ui'

const propTypes = {
  data: PropTypes.shape({
    showAvailabilityBar: PropTypes.bool.isRequired,
  }).isRequired,
}

const AvailabilityBar = ({ data }) => {
  let q = ''
  let { showAvailabilityBar } = data
  const price = '$1.00'
  const domainTLD = 'ca'
  const [domainName, setDomainName] = useState(false)
  const [domainNameLoaded, setDomainNameLoaded] = useState(false)
  const [addToCartError, setAddToCartError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getDomainName = () => {
    const { search } = window.location
    const params = new URLSearchParams(search)
    q = params.get('q')
    if (!q) {
      showAvailabilityBar = false
      return false
    }
    const [name] = q.split('.')
    setDomainName(name.toLowerCase().replace(/[^a-z0-9-\u00e0-\u00f6]/g, ''))
    return true
  }

  useEffect(() => {
    if (hasDOM) {
      setDomainNameLoaded(getDomainName())
    }
  })

  const addToCartPath = `SearchData.ashx?a=b&name=${domainName}&tld=${domainTLD}&phase=ga&status=Available&term=1&registryPremium=false`

  const addToCartPathProd = {
    addToCartPathEndpoint: `https://www.rebel.com/${addToCartPath}`,
  }
  const addToCartPathDev = {
    addToCartPathEndpoint: `https://dev.rebel.com/${addToCartPath}`,
  }
  const addToCartPathLocal = {
    addToCartPathEndpoint: `https://local.rebel.com/${addToCartPath}`,
  }

  const { addToCartPathEndpoint } =
    // eslint-disable-next-line no-undef
    (__LOCAL__ && addToCartPathLocal) ||
    (__DEV__ && addToCartPathDev) ||
    (__PROD__ && addToCartPathProd)

  const addToCart = () => {
    return (
      fetch(addToCartPathEndpoint)
        // eslint-disable-next-line promise/prefer-await-to-then
        .then(response => {
          return response.json()
        })
    )
  }

  const handleClickAction = () => {
    setIsLoading(true)
    // eslint-disable-next-line promise/prefer-await-to-then
    addToCart()
      .then(response => {
        if (response.result) {
          setIsLoading(false)
          window.location = '/order'
        } else {
          setAddToCartError(true)
          setIsLoading(false)
        }
      })
      .catch(() => {
        setAddToCartError(true)
        setIsLoading(false)
      })
  }

  if (showAvailabilityBar) {
    return (
      <div>
        {domainNameLoaded && (
          <Stack
            alignItems="stretch"
            as="section"
            justifyContent="center"
            overflow="hidden"
            position="relative"
            width="100%"
          >
            {addToCartError && (
              <Box
                bg="redNew"
                flexWrap="wrap"
                justifyContent="center"
                px={2}
                py={2}
              >
                <Text color="white" fontSize={2} justifyContent="center">
                  Something happened, we are unable to add this domain to your
                  cart.
                </Text>
              </Box>
            )}
            <Stack
              alignSelf="center"
              maxWidth="containers.lg"
              padding="20px"
              width="100%"
            >
              <Text color="greenDark" fontSize={3} fontWeight="bold" my={3}>
                Your Domain is Available!
              </Text>
              <Box flexWrap="wrap" justifyContent="space-between">
                <Text fontSize={3} fontWeight="bold">
                  {domainName}.{domainTLD}
                </Text>
                <Box flexWrap="wrap" justifyContent="space-between">
                  <Text fontSize={3} fontWeight="bold">
                    {price}
                  </Text>
                  <Button
                    ariaLabel="Add Domain To Cart"
                    disabled={isLoading}
                    mx={3}
                    onClick={() => handleClickAction()}
                    rel="nofollow"
                    success
                    variant="solid"
                  >
                    {isLoading ? 'Updating your cart...' : 'Add to Cart'}
                  </Button>
                </Box>
              </Box>
            </Stack>
          </Stack>
        )}
      </div>
    )
  }
  return null
}
AvailabilityBar.propTypes = propTypes

export { AvailabilityBar }

export const AvailabilityBarFragment = graphql`
  fragment AvailabilityBarPanel on Panel {
    id
    showAvailabilityBar
  }
`
