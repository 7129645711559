import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link as GatsbyLink } from 'gatsby'
import {
  Box,
  Link,
  Text,
  Section,
  Button,
  theme as systemTheme,
} from '@rebeldotcom/components'
import { css } from 'styled-components'
import { Title } from '../../title'
import Img from '../../image'
import { containerVariants, handleCTAClick } from '../../../utilities/panels'

const propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string,
    cta: PropTypes.shape({
      color: PropTypes.string,
      href: PropTypes.string,
      id: PropTypes.string.isRequired,
      isCMS: PropTypes.bool,
      size: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.oneOf(['link', 'addToCart']),
      variant: PropTypes.string,
    }),
    id: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        altText: PropTypes.string,
        path: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({}),
          }),
        }),
      })
    ),
    layout: PropTypes.oneOf(['inline', 'alternate']),
    theme: PropTypes.oneOf(['light', 'dark']),
    title: PropTypes.shape({
      bottom: PropTypes.string,
      main: PropTypes.shape({
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
      top: PropTypes.shape({
        bgColor: PropTypes.string,
        text: PropTypes.string,
        textColor: PropTypes.string,
      }),
    }),
  }).isRequired,
}

const ImageTrinity = ({ data }) => {
  const { title, images, content, cta, theme, layout, id } = data

  const getColumnDivisor = () => {
    if (images.length <= 4) return images.length
    if (images.length <= 6) return 3
    return 4
  }

  const columnDivision = 1 / getColumnDivisor()

  const imageLayoutCss =
    layout === 'alternate'
      ? css`
          &:nth-child(odd) {
            margin-top: ${systemTheme.space[6]};
          }
        `
      : {}

  const renderCTA = () => {
    if (!cta) return null
    if (cta.type === 'addToCart') {
      return (
        <Button
          key={cta.id}
          ariaLabel={cta.title}
          color={cta.color}
          id={cta.id}
          onClick={() => handleCTAClick(cta)}
          rel="nofollow"
          variant={cta.variant}
        >
          {cta.title}
        </Button>
      )
    }
    return (
      <Link
        ariaLabel={cta.title}
        as={cta.isCMS ? GatsbyLink : Link}
        color={cta.color}
        href={cta.href}
        id={cta.id}
        mt={5}
        size={cta.size}
        title={cta.title}
        to={cta.href}
        variant={cta.variant}
      >
        {cta.title}
      </Link>
    )
  }

  return (
    <Section
      bg={containerVariants[theme].bg}
      color={containerVariants[theme].text}
      containerProps={{
        maxWidth: 'containers.lg',
      }}
      id={id}
      py={[4, 5]}
    >
      <Title theme={theme} title={title} />
      <Text
        fontSize={1}
        maxWidth="containers.md"
        mt={4}
        px={3}
        textAlign="center"
      >
        {content}
      </Text>

      <Box flexWrap="wrap" justifyContent="center" mt={4} width="100%">
        {images.map((image, idx) => {
          const fluid = image ? image.path.childImageSharp.fluid : {}
          const alt = image ? image.altText : {}

          return (
            <Box
              key={idx}
              alignItems="center"
              as={Text}
              css={imageLayoutCss}
              mx={[5, 0]}
              my={[4, 0]}
              px={3}
              width={[1, columnDivision]}
            >
              <Img
                alt={alt}
                display={['flex']}
                fluid={fluid}
                objectFit="contain"
                title={alt}
                width={[1, 1, 1]}
              />
            </Box>
          )
        })}
      </Box>

      {renderCTA()}
    </Section>
  )
}

ImageTrinity.propTypes = propTypes
export { ImageTrinity }

export const imageTrinityFragment = graphql`
  fragment ImageTrinityPanel on Panel {
    title {
      ...TitleProps
    }
    content
    theme
    layout
    images {
      ...ImageProps
    }
    cta {
      ...CTAProps
    }
  }
`
